/* eslint-disable import/no-anonymous-default-export */
import AxiosInstance from './AxiosInstance';
import axios from 'axios';

const BASE_API = process.env.REACT_APP_API_BASE_URL;

const token = localStorage.getItem('token');

export default {
  signIn: async (username, password) => {
    const bodyXXX = `client=react&username=${username}&password=${password}&grant_type=password`;
    try {
      const response = await axios.post(
        `${BASE_API}/oauth/token`,
        bodyXXX,
        {
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type':
              'application/x-www-form-urlencoded',
            Authorization: 'Basic cmVhY3Q6Y2xpZW50UmVhY3Qw',
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  addAccount: async (
    username,
    cpfCnpj,
    rg,
    birthDate,
    typePerson,
    email,
    phoneNumber,
    pass,
    stateRegistration,
    interestProduct,
    activity,
    tax,
    deliveryInformation,
    paymentTerms,
    deadlineApproval,
    cep,
    district,
    addressNumber,
    street,
    complement,
    state,
    city,
    origin
  ) => {
    const response = await AxiosInstance.post(
      `${BASE_API}/v1/accounts`,
      {
        username,
        cpfCnpj,
        rg,
        birthDate,
        typePerson,
        email,
        phoneNumber,
        pass,
        stateRegistration,
        interestProduct,
        activity,
        tax,
        deliveryInformation,
        paymentTerms,
        deadlineApproval,
        cep,
        district,
        addressNumber,
        street,
        complement,
        state,
        city,
        origin,
      },
      {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        return res;
      })
      .catch((err) => err);

    return response;
  },
  accountTokenConfirm: async (accountId, tokenConfirm) => {
    const body = JSON.stringify({ tokenConfirm });
    const req = await AxiosInstance.put(
      `/v1/accounts/confirm-account/${accountId}`,
      body
    );
    return req;
  },
  sendForgotPasswordType: async (accountId, type) => {
    const body = JSON.stringify({ type });
    const req = await AxiosInstance.post(
      `/v1/accounts/forgot-password/${accountId}`,
      body
    )
      .then((resp) => resp)
      .catch((err) => err);
    return req;
  },
  sendUpdatePasswordForgotPassword: async (
    accountId,
    tokenConfirm,
    newPassword
  ) => {
    const body = JSON.stringify({
      tokenConfirm,
      newPassword,
    });
    const req = await AxiosInstance.post(
      `/v1/accounts/forgot-password/${accountId}/update-password`,
      body
    )
      .then((resp) => resp)
      .catch((err) => err);
    return req;
  },
  forgotPasswordValidateToken: async (
    accountId,
    tokenConfirm
  ) => {
    const body = JSON.stringify({ tokenConfirm });
    const req = await AxiosInstance.post(
      `/v1/accounts/forgot-password/${accountId}/validate-token`,
      body
    );
    return req;
  },
  updatePassword: async (
    accountId,
    oldPassword,
    newPassword
  ) => {
    const body = JSON.stringify({
      newPassword,
      oldPassword,
    });
    const req = await AxiosInstance.put(
      `/v1/accounts/updatePassword/${accountId}`,
      body
    );
    return req;
  },
  updateDistancy: async (
    accountId,
    distancyCities,
    distancyValue
  ) => {
    const req = await AxiosInstance.put(
      `/v1/accounts/distancy/${accountId}`,
      {
        body: JSON.stringify({
          distancyCities,
          distancyValue,
        }),
      }
    );
    return req;
  },
  updateAccount: async (
    accountId,
    username,
    cpfCnpj,
    rg,
    birthDate,
    typePerson,
    email,
    phoneNumber,
    pass,
    stateRegistration,
    interestProduct,
    activity,
    tax,
    deliveryInformation,
    paymentTerms,
    deadlineApproval,
    cep,
    district,
    addressNumber,
    street,
    complement,
    state,
    city
  ) => {
    const body = JSON.stringify({
      accountId,
      username,
      cpfCnpj,
      rg,
      birthDate,
      typePerson,
      email,
      phoneNumber,
      pass,
      stateRegistration,
      interestProduct,
      activity,
      tax,
      deliveryInformation,
      paymentTerms,
      deadlineApproval,
      cep,
      district,
      addressNumber,
      street,
      complement,
      state,
      city,
    });
    console.log(body);
    const req = await AxiosInstance.put(
      `/v1/accounts/${accountId}`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return req;
  },
  getHires: async (accountId) => {
    const req = await AxiosInstance.get(
      `/v1/accounts/hires/${accountId}`
    )
      .then((response) => response.data)
      .catch((err) => console.log(err));
    return req;
  },
  deleteAccount: async (accountId) => {
    const req = await AxiosInstance.delete(
      `/v1/accounts/${accountId}`
    );
    return req;
  },
  restoreAccount: async (accountId) => {
    const req = await AxiosInstance.put(
      `/v1/accounts/${accountId}/restore`
    );
    return req;
  },
  getOffersNumberBySide: async (side) => {
    const req = await AxiosInstance.get(
      `/v1/bids/number-bids-by-product/${side}`
    )
      .then((response) => response.data)
      .catch((err) => console.log(err));
    return req;
  },
  getAllBids: async (
    page,
    pageSize,
    startDate,
    endDate
  ) => {
    const req = await AxiosInstance.get(`/v1/bids`, {
      params: {
        page: page,
        size: pageSize,
        startDate: startDate,
        endDate: endDate,
      },
    });
    return req;
  },
  sendNewOrder: async (
    accountId,
    username,
    product,
    side,
    price,
    payment,
    obs,
    freight,
    bagsMinimumApplication,
    bagsTotal,
    ipAddress,
    modality,
    deliveryMonth,
    cep,
    acceptCounterBid,
    bestPrice,
    storage,
    quantity
  ) => {
    bagsTotal = parseFloat(bagsTotal);
    bagsMinimumApplication = parseFloat(
      bagsMinimumApplication
    );
    const body = JSON.stringify(
      {
        accountId,
        username,
        product,
        side,
        price,
        quantity,
        payment,
        obs,
        freight,
        bagsMinimumApplication,
        bagsTotal,
        ipAddress,
        deliveryMonth,
        cep,
        modality,
        acceptCounterBid,
        bestPrice,
        storage,
      },
      null,
      '\t'
    );
    console.log('Body Request: ', body);
    const req = await AxiosInstance.post(
      `/v1/bids/${accountId}`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return req;
  },

  sendNewOrderSell: async (
    accountId,
    username,
    product,
    side,
    quantity,
    price,
    payment,
    obs,
    freight,
    bagsMinimumApplication,
    bagsTotal,
    modality,
    storage,
    deliveryMonth,
    cep,
    acceptCounterBid,
    bestPrice
  ) => {
    quantity = parseFloat(quantity);
    price = price.replace(',', '.');
    price = parseFloat(price);
    bagsTotal = parseFloat('0');
    bagsMinimumApplication = parseFloat('0');
    const body = JSON.stringify({
      username,
      product,
      side,
      quantity,
      price,
      payment,
      obs,
      freight,
      bagsMinimumApplication,
      bagsTotal,
      storage,
      deliveryMonth,
      cep,
      modality,
      acceptCounterBid,
      bestPrice,
    });
    console.log('Body Request: ', body);
    const req = await AxiosInstance.post(
      `/v1/bids/${accountId}`,
      body
    );
    return req;
  },
  getOrdersByAccount: async (accountId) => {
    const req = await AxiosInstance.get(
      `/v1/bids/account/${accountId}`
    )
      .then((response) => response.data)
      .catch((err) => console.log(err));
    return req;
  },
  getOrderById: async (orderId) => {
    const req = await AxiosInstance.get(
      `/v1/bids/${orderId}`
    );
    return req;
  },
  getBidsNearby: async (orderId) => {
    const req = await AxiosInstance.get(
      `/v1/bids/bids-nearby/${orderId}`,
      {}
    );
    return req;
  },
  inactivateOrder: async (orderId) => {
    const req = await AxiosInstance.put(
      `/v1/bids/inactivate/${orderId}`
    );
    return req;
  },

  getNotificationsByAccount: async (accountId) => {
    const req = await AxiosInstance.get(
      `/v1/accounts/notifications/${accountId}`,
      {}
    )
      .then((response) => response.data)
      .catch((err) => console.log(err));
    return req;
  },
  getDataMarket: async () => {
    const req = await AxiosInstance.get(`/v1/market-data`)
      .then((response) => response.data)
      .catch((err) => console.log(err));
    return req;
  },
  sendDataMarket: async (
    date,
    lastPrice,
    origin,
    symbol,
    variation
  ) => {
    const body = JSON.stringify({
      date,
      lastPrice,
      origin,
      symbol,
      variation,
    });
    const req = await AxiosInstance.post(
      `/v1/market-data`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return req;
  },
  getDataMarketBySymbol: async (symbol) => {
    const req = await AxiosInstance.get(
      `/v1/market-data/${symbol}`
    )
      .then((response) => response.data)
      .catch((err) => console.log(err));
    return req;
  },
  getAccountById: async (accountId) => {
    const req = await AxiosInstance.get(
      `/v1/accounts/${accountId}`
    );
    return req;
  },
  getAccounts: async (
    page,
    pageSize,
    startDate,
    endDate
  ) => {
    const req = await AxiosInstance.get(
      `/v1/accounts/all`,
      {
        params: {
          page: page,
          size: pageSize,
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    return req;
  },
  getAccountByDocument: async (document) => {
    const req = await AxiosInstance.get(
      `/v1/accounts/document/${document}`
    )
      .then((response) => response.data)
      .catch((err) => console.log(err));
    return req;
  },
  validateAccount: async (
    accountId,
    cpfCnpj,
    typePerson,
    activity,
    addressNumber,
    birthDate,
    buyPower,
    deadlineApproval,
    interestProduct,
    district,
    paymentTerms,
    responsible,
    rg,
    stateRegistration,
    street,
    tax,
    deliveryInformation,
    damp
  ) => {
    const body = JSON.stringify({
      cpfCnpj,
      typePerson,
      activity,
      addressNumber,
      birthDate,
      buyPower,
      deadlineApproval,
      interestProduct,
      district,
      paymentTerms,
      responsible,
      rg,
      stateRegistration,
      street,
      tax,
      deliveryInformation,
      damp,
    });
    const req = await AxiosInstance.put(
      `/v1/accounts/validate/${accountId}`,
      body
    );
    return req;
  },
  getAveragePriceOrders: async () => {
    const req = await AxiosInstance.get(
      `/v1/bids/average-price`
    )
      .then((response) => response.data)
      .catch((err) => console.log(err));
    return req;
  },
  getAccountByEmail: async (email) => {
    const req = await AxiosInstance.get(
      `/v1/accounts/email/${email}`
    );
    return req;
  },
  updateOrderBuy: async (
    orderId,
    bagsMinimumApplication,
    bagsTotal,
    freight,
    payment,
    price,
    acceptCounterBid,
    bestPrice,
    quantity
  ) => {
    bagsTotal = parseFloat(bagsTotal);
    bagsMinimumApplication = parseFloat(
      bagsMinimumApplication
    );
    quantity = bagsTotal
    const bodyJson = JSON.stringify({
      bagsMinimumApplication,
      bagsTotal,
      freight,
      payment,
      price,
      acceptCounterBid,
      bestPrice,
      quantity
    });
    console.log('bodyEditorder', bodyJson);
    const req = await AxiosInstance.put(
      `/v1/bids/${orderId}/buy-edit`,
      bodyJson
    );
    return req;
  },
  updateOrderSell: async (
    orderId,
    bagsMinimumApplication,
    bagsTotal,
    freight,
    payment,
    price,
    acceptCounterBid,
    bestPrice,
    quantity
  ) => {
    bagsTotal = parseFloat(bagsTotal);
    bagsMinimumApplication = parseFloat(
      bagsMinimumApplication
    );
    quantity = bagsTotal
    const bodyJson = JSON.stringify({
      bagsMinimumApplication,
      bagsTotal,
      freight,
      payment,
      price,
      acceptCounterBid,
      bestPrice,
      quantity
    });
    console.log('bodyEditorder', bodyJson);
    const req = await AxiosInstance.put(
      `/v1/bids/${orderId}/sell-edit`,
      bodyJson
    );
    return req;
  },
  sendToken: async (accountId) => {
    const req = await AxiosInstance.post(
      `/v1/tokens/${accountId}/send-sms`
    )
      .then((resp) => resp)
      .catch((err) => err);
    return req;
  },
  getNegotiationsByUser: async (accountId, status) => {
    const req = await AxiosInstance.get(
      `/v1/negotiations`,
      {
        params: {
          accountId,
          status,
        },
      }
    );
    return req;
  },
  updateNegotiation: async (negotiationId, status) => {
    const req = await AxiosInstance.put(
      `/v1/negotiations/define-status/${negotiationId}?status=${status}`
    );
    return req;
  },
  getAccountTimeline: async (accountId) => {
    const req = await AxiosInstance.get(
      `/v1/accounts/timeline/${accountId}`
    );
    return req;
  },
  createNegotiation: async (
    bidId,
    bidNegotiateId,
    price,
    quantity
  ) => {
    const origin = 'CONTACT';
    const bodyJson = JSON.stringify({
      price,
      quantity,
      origin,
    });
    const req = await AxiosInstance.post(
      `/v1/negotiations/${bidId}/${bidNegotiateId}`,
      bodyJson,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return req;
  },
  getBidsPercentage: async () => {
    const req = await AxiosInstance.get(
      `/v1/bids/dashboards/products-percentage?side=V`
    );
    return req;
  },
  getMarketData: async () => {
    const req = await AxiosInstance.get(
      '/v1/market-data?origin=BOLSA'
    );
    return req;
  },
  getLastDataMarket: async () => {
    const req = await AxiosInstance.get(`/v1/market-data/last-quotation`);
    return req;
  },
  getAllNegotiations: async () => {
    const req = await AxiosInstance.get('/v1/negotiations');
    return req;
  },
  generateBuyerContract: async (
    negotiationId,
    cep,
    ci,
    ciDispatched,
    deadlineDelivery,
    deliveryPlace,
    document,
    funruralDiscountNote,
    grainType,
    number,
    maritalStatus,
    name,
    quantity,
    responsibleForDelivery,
    ruralBackground,
    stateRegistration,
    vintage,
    city,
    complement,
    neighborhood,
    state,
    street
  ) => {
    const bodyJson = JSON.stringify({
      cep,
      ci,
      ciDispatched,
      deadlineDelivery,
      deliveryPlace,
      document,
      funruralDiscountNote,
      grainType,
      number,
      maritalStatus,
      name,
      quantity,
      responsibleForDelivery,
      ruralBackground,
      stateRegistration,
      vintage,
      city,
      complement,
      neighborhood,
      state,
      street,
    });
    const req = await AxiosInstance.post(
      `/v1/contracts/${negotiationId}/buyer`,
      bodyJson
    );
    return req;
  },
  generateSellerContract: async (
    negotiationId,
    cep,
    ci,
    ciDispatched,
    deadlineDelivery,
    deliveryPlace,
    document,
    funruralDiscountNote,
    grainType,
    number,
    maritalStatus,
    name,
    quantity,
    responsibleForDelivery,
    ruralBackground,
    stateRegistration,
    vintage,
    city,
    complement,
    neighborhood,
    state,
    street
  ) => {
    const bodyJson = JSON.stringify({
      cep,
      ci,
      ciDispatched,
      deadlineDelivery,
      deliveryPlace,
      document,
      funruralDiscountNote,
      grainType,
      number,
      maritalStatus,
      name,
      quantity,
      responsibleForDelivery,
      ruralBackground,
      stateRegistration,
      vintage,
      city,
      complement,
      neighborhood,
      state,
      street,
    });
    const req = await AxiosInstance.post(
      `/v1/contracts/${negotiationId}/seller`,
      bodyJson
    );
    return req;
  },
  createCounterBid: async (
    bidId,
    bidNegotiateId,
    price,
    quantity
  ) => {
    const bodyJson = JSON.stringify({
      price,
      quantity,
    });
    const req = await AxiosInstance.post(
      `/v1/counter-bids/${bidId}/${bidNegotiateId}`,
      bodyJson
    );
    return req;
  },
  getCounterBids: async (accountId, page, size) => {
    const req = await AxiosInstance.get(
      `/v1/counter-bids`,
      {
        params: {
          accountId: accountId,
          page: page,
          size: size,
        },
      }
    );
    return req;
  },
  activateAccount: async (accountId) => {
    const req = await AxiosInstance.put(
      `/v1/accounts/${accountId}/activation`
    );
    return req;
  },
  deactivateAccount: async (accountId) => {
    const req = await AxiosInstance.put(
      `/v1/accounts/${accountId}/deactivate`
    );
    return req;
  },
  signContract: async (
    contractId,
    accountId,
    urlContractSigned
  ) => {
    const req = await AxiosInstance.post(
      `/v1/signs/${contractId}/${accountId}`,
      {
        urlContractSigned: urlContractSigned,
      }
    );
    return req;
  },
  getAllContracts: async (page, size) => {
    const req = await AxiosInstance.get(`/v1/contracts`, {
      params: {
        page,
        size,
      },
    });
    return req;
  },
  getAllNegotiationsIds: async () => {
    const req = await AxiosInstance.get(
      `/v1/negotiations/ids`
    );
    return req;
  },
  getNegotiationsById: async (negotiationId) => {
    const req = await AxiosInstance.get(
      `/v1/negotiations/${negotiationId}`
    );
    return req;
  },
  getOperations: async (page, pageSize) => {
    const req = await AxiosInstance.get(`/v1/operations`, {
      params: {
        page: page,
        size: pageSize,
      },
    });
    return req;
  },
  getOperationById: async (operationId) => {
    const req = await AxiosInstance.get(
      `/v1/operations/${operationId}`
    );
    return req;
  },

  getEmails: async () => {
    const req = await AxiosInstance.get(
      '/v1/accounts/emails'
    );
    return req;
  },
};
