import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import 'moment/locale/pt-br';

import reportWebVitals from './reportWebVitals';
import { store } from './core/store';
import MuiLayout from './app/layouts/Default/MuiLayout';
import Routes from './app/routes'
import './auth/httpConfig';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


ReactDOM.render(
  <div style={{overflow: 'auto'}}>
    <React.StrictMode>
      <ConfigProvider>
        <Provider store={store}>
          <BrowserRouter>
            <Switch>
              <Route path="/login">
                <Routes />
              </Route>
              <Route path="/">
                <MuiLayout>
                  <Routes />
                </MuiLayout>
              </Route>
            </Switch>
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </React.StrictMode>
  </div>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
