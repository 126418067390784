import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Tag,
  Typography,
  notification,
} from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Profile from './../../assets/Profile.png';
import CashInput from '../components/CashInput/CashInput';
import { BidService, NegotiationService } from 'uai-graos-sdk';

export default function NegotiationsCreate() {
  const [bids, setBids] = useState<any>([]);
  const [bidsNearby, setBidsNearby] = useState<any>([]);
  const [
    selectedBidNegotiate,
    setSelectedBidNegotiate,
  ] = useState<any>(null);
  const [selectedBid, setSelectedBid] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const onFinishNegotiation = async (values: any) => {
    function filterPrice(price: string) {
      if (!price) return;
      const replacePrice = price.slice(3);
      return parseFloat(replacePrice);
    }

    return await NegotiationService.createNegotiation(
      values.bidBuyerId,
      values.bidSellerId,
      {
        price: filterPrice(values.price)!,
        quantity: parseFloat(values.quantity),
        origin: 'DEFAULT'
      }
    )
      .then(() => {
        notification.success({
          message: 'Sucesso!',
          description: 'A negociação foi criada!',
        });
      })
  };

  useEffect(() => {
    getAllBids();
  }, []);

  const getBidNegotiateDetails = async (bidId: any) => {
    return await BidService.getBidById(bidId)
      .then(setSelectedBidNegotiate)

  };

  const getBidDetails = async (bidId: any) => {
    return await BidService.getBidById(bidId)
      .then((res) => {
        setSelectedBid(res);
        getBidsNearby(bidId);
      })
  };

  const getBidsNearby = async (bidId: any) => {
    return await BidService.getBidsNearby(bidId).then((res) => {
      setBidsNearby(res.nearbys);
    });
  };

  const getAllBids = async () => {
    return await BidService.getAllBids({}).then((res) => {
      setBids(res.content)
      setLoading(false);
    })
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Form layout='vertical' onFinish={onFinishNegotiation}>
      <Row gutter={24}>
        <Col xs={24} lg={12}>
          <Form.Item
            name='bidBuyerId'
            label='Informe o ID da oferta do usuário'
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Select
              placeholder='Selecione a oferta'
              showSearch
              onChange={(e) => getBidDetails(e)}
            >
              {bids.map((a: any) => (
                <Select.Option key={a.id} value={a.id}>
                  {a.id}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name='bidSellerId'
            label='Informe o ID da oferta da contraparte'
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Select
              placeholder='Selecione a oferta'
              showSearch
              onChange={(e) => getBidNegotiateDetails(e)}
              value={selectedBidNegotiate?.id}
            >
              {bidsNearby?.map((a: any) => (
                <Select.Option
                  key={a.id}
                  value={a ? a.id : null}
                >
                  {a.id}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name='price'
            label='Preço'
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <CashInput />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name='quantity'
            label='Quantidade'
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={24} style={{ marginTop: 24 }}>
        {selectedBid ? (
          <Col xs={24} lg={12}>
            <Typography.Title level={3}>
              Oferta
            </Typography.Title>
            <Descriptions column={1} bordered size='small'>
              <Descriptions.Item label={'ID da oferta'}>
                {selectedBid?.id}
              </Descriptions.Item>
              <Descriptions.Item label={'Nome'}>
                <Typography.Text strong>
                  <Avatar
                    size={20}
                    style={{ marginRight: 10 }}
                    src={Profile}
                  />
                  <Link
                    to={`/users/${selectedBid?.account.id}`}
                  >
                    {selectedBid?.account.username}
                  </Link>
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label={'Telefone'}>
                {selectedBid?.account.phoneNumber}
              </Descriptions.Item>
              <Descriptions.Item label={'E-Mail'}>
                {selectedBid?.account.email}
              </Descriptions.Item>
              <Descriptions.Item
                label={'Aceita Contraproposta?'}
              >
                {selectedBid?.acceptCounterBid ? (
                  <Tag color='cyan'>Sim</Tag>
                ) : (
                  <Tag color='red'>Não</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={'Tipo de oferta'}>
                {selectedBid?.side === 'C' ? 'Compra' : 'Venda'}
              </Descriptions.Item>
              <Descriptions.Item label={'Quantidade'}>
                {selectedBid?.quantity}
              </Descriptions.Item>
              <Descriptions.Item label={'Produto'}>
                {selectedBid?.product}
              </Descriptions.Item>
              <Descriptions.Item label={'Preço'}>
                <Tag color='green'>
                  R$ {selectedBid?.price}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label={'Tipo de frete'}>
                {!selectedBid?.freight ? 'N/A' : selectedBid?.freight}
              </Descriptions.Item>
              <Descriptions.Item
                label={'Tipo de pagamento'}
              >
                {!selectedBid?.payment ? 'N/A' : selectedBid?.payment}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        ) : null}
        {selectedBidNegotiate && bidsNearby ? (
          <Col xs={24} lg={12}>
            <Typography.Title level={3}>
              Oferta da contraparte
            </Typography.Title>
            <Descriptions column={1} bordered size='small'>
              <Descriptions.Item label={'ID da oferta'}>
                {selectedBidNegotiate?.id}
              </Descriptions.Item>
              <Descriptions.Item
                label={'Nome da contraparte'}
              >
                <Typography.Text strong>
                  <Avatar
                    size={20}
                    style={{ marginRight: 10 }}
                    src={Profile}
                  />
                  <Link
                    to={`/users/${selectedBidNegotiate?.account.id}`}
                  >
                    {selectedBidNegotiate?.account.username}
                  </Link>
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label={'Telefone'}>
                {selectedBidNegotiate?.account.phoneNumber}
              </Descriptions.Item>
              <Descriptions.Item label={'E-Mail'}>
                {selectedBidNegotiate?.account.email}
              </Descriptions.Item>
              <Descriptions.Item
                label={'Aceita Contraproposta?'}
              >
                {selectedBidNegotiate?.acceptCounterBid ? (
                  <Tag color='cyan'>Sim</Tag>
                ) : (
                  <Tag color='red'>Não</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={'Tipo de oferta'}>
                {selectedBidNegotiate?.side === 'C' ? 'Compra' : 'Venda'}
              </Descriptions.Item>
              <Descriptions.Item label={'Quantidade'}>
                {selectedBidNegotiate?.quantity}
              </Descriptions.Item>
              <Descriptions.Item label={'Produto'}>
                {selectedBidNegotiate?.product}
              </Descriptions.Item>
              <Descriptions.Item label={'Preço'}>
                <Tag color='green'>
                  R$ {selectedBidNegotiate?.price}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label={'Tipo de frete'}>
                {!selectedBidNegotiate?.freight ? 'N/A' : selectedBidNegotiate?.freight}
              </Descriptions.Item>
              <Descriptions.Item
                label={'Tipo de pagamento'}
              >
                {!selectedBidNegotiate?.payment ? 'N/A' : selectedBidNegotiate?.payment}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col xs={24} lg={8}>
          <Button
            style={{
              marginTop: 20,
            }}
            type='primary'
            htmlType='submit'
            size='large'
          >
            Criar negociação
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
