import { useEffect } from 'react';
import styled from 'styled-components';
import CircleChart from '../components/CircleChart';
import { Card, Col } from 'antd';
import useStats from '../../core/hooks/useStats';


export default function BidPercentageCharts() {

  const {
    bidPercentageBuy,
    bidPercentageSell,
    fetchBidPercentageBuy,
    fetchBidPercentageSell
  } = useStats()

  useEffect(() => {
    fetchBidPercentageBuy();
    fetchBidPercentageSell();
  }, [fetchBidPercentageBuy, fetchBidPercentageSell])


  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <CardContainer>
          <CardTitle>
            Percentual de ofertas de compra:
          </CardTitle>
          <Wrapper>
            {bidPercentageBuy?.map((item) => (
              <CircleChart
                progress={item.percentage || 0}
                size={88}
                theme={item?.name === 'Milho' ? 'primary' : 'default'}
                caption={item?.name}
              />
            ))}
          </Wrapper>
        </CardContainer>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <CardContainer>
          <CardTitle>
            Percentual de ofertas de venda:
          </CardTitle>
          <Wrapper>
            {bidPercentageSell?.map((item) => (
              <CircleChart
                progress={item.percentage || 0}
                size={88}
                theme={item?.name === 'Milho' ? 'primary' : 'default'}
                caption={item?.name}
              />
            ))}
          </Wrapper>
        </CardContainer>
      </Col>
    </>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
`;

const CardContainer = styled(Card)`
  background-color: #fff;
  @media screen and (max-width: 1190px) {
  display: none;
}
`

const CardTitle = styled.h3`
  margin-top: 10px;
  color: #8c8c8c;
`