/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Table,
  Tag,
  notification,
} from 'antd';
import { useEffect, useState } from 'react';
import Api from '../services/Api';
import moment from 'moment';

export default function CounterBidList() {
  const { Item } = Form;
  const { Option } = Select;
  const [counterBids, setCounterBids] = useState([]);
  const [account, setAccount] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [accountsEmails, setAccountsEmails] = useState([]);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  })

  useEffect(() => {
    getCounterBids(account, pagination.current, pagination.pageSize);
    getAccounts();
  }, [account]);

  const getAccounts = () => {
    Api.getEmails()
      .then((resp) => {
        setAccountsEmails(resp.data.accountsEmails);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if(err.response.status === 404){
            notification.error({
              message: 'Erro!',
              description: `E-mail não encontrado!Erro código: ${err.response.status}`,
            });
          }
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
          });
        } else if (err.request) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
        notification.error({
          message: 'Erro!',
          description: `Por favor entre em contato com seu administrador! Erro código: ${err}`,
        });
        setLoading(false);
      });
  };

  const getCounterBids = async (account: string | null, page: number, size: number) => {
    return await Api.getCounterBids(account, page, size)
      .then((res) => {
        const { content, totalElements } = res.data;
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalElements,
          showTotal: (total: number, range: any) =>
            `${range[0]}-${range[1]} de ${total} registros`,
          current: page,
        }));
        setCounterBids(content);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
          });
        } else if (err.request) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
        notification.error({
          message: 'Erro!',
          description: `Por favor entre em contato com seu administrador! Erro código: ${err}`,
        });
        setLoading(false);
      });
  };
  const handlePaginationChange = (
    page: any,
    pageSize: any
  ) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page - 1,
      pageSize: pageSize,
    }));

    getCounterBids(account, page - 1, pageSize);
  };
  const onFinishAccountSearch = async (values: any) => {
    setLoading(true);
    await Api.getAccountByEmail(values.accountId).then((resp) => {
      setAccount(resp.data.id)
    })
  };

  const columns = [
    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (item: any) => {
        return (
          <div>
            {moment(item).format('DD/MM/YYYY  HH:mm')}
          </div>
        );
      },
    },
    {
      title: 'Preço',
      dataIndex: 'price',
      key: 'price',
      render: (item: any) => {
        return (
          <div>
            <Tag color='green'>
              R$
              {item.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Tag>
          </div>
        );
      },
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item: any) => {
        return (
          <div>
            <Tag
              color={item === 'REFUSED' ? 'red' : 'green'}
            >
              {item === 'REFUSED' ? 'Recusada' : 'Aprovada'}
            </Tag>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={24}>
        <Col lg={12}>
          <Form
            layout='vertical'
            onFinish={onFinishAccountSearch}
          >
            <Item label='Usuário' name='accountId'>
              <Select placeholder='Selecione o usuário'>
                {accountsEmails.map((account: any) => (
                  <Option
                    value={account}
                    key={account}
                  >
                    {account}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item>
              <Button type='primary' htmlType='submit'>
                Pesquisar por usuário
              </Button>
            </Item>
          </Form>
        </Col>
        <Col lg={24} md={12}>
          <Table
            columns={columns}
            loading={loading}
            pagination={{
              ...pagination,
              current: pagination.current + 1,
              onChange: handlePaginationChange,
            }}
            dataSource={counterBids}
          />
        </Col>
      </Row>
    </>
  );
}
