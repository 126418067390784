import { notification } from 'antd';
import axios from 'axios';
import AuthService from '../../auth/Authorization.service';

const APP_BASE_URL = process.env.REACT_APP_BACK_OFFICE_BASE_URL;
const BASE_API = process.env.REACT_APP_API_BASE_URL;

const AxiosInstance = axios.create({
  baseURL: BASE_API,
  headers: {
    'Content-Type': 'application/json;',
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300; // personaliza a validação de status
  },
});

// Adiciona um interceptor de resposta para verificar erros de autenticação
AxiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if(axios.isAxiosError(error)){
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('accountId');
        window.location.href = `${APP_BASE_URL}/login`
        notification.error({
          message: 'Erro!',
          description:
          'Sua sessão expirou! Por favor faça o login novamente!',
        });
      }
      if (error.response.status === 400) {
        const { notifications } = error.response.data
        notification.error({
          message: 'Erro!',
          description:
          `${notifications}`,
        });
      }
        const { notifications } = error.response.data;
        if (error.response.status === 412) {
          notification.error({
            message: 'Erro!',
            description: `${notifications}`,
          });
          return;
        }
        notification.error({
          message: 'Erro!',
          description: `Erro código ${error.response.status}, por favor entre em contato com o Administrador`,
        });
    }
    return Promise.reject(error);
  }
);

// adiciona um interceptor para validar o token
AxiosInstance.interceptors.request.use((config) => {
  const token = AuthService.getAccessToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default AxiosInstance;
