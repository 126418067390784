import React, { useEffect } from 'react';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import BidPercentageCharts from '../features/BidPercentageCharts';
import { Card, Col, Row, Statistic } from 'antd';
import useStats from '../../core/hooks/useStats';
import MarketDataCard from '../components/MarketDataCard/MarketDataCard';


interface statsProps {
  key: string;
  number?: number;
  description?: string;
}

const cardStyle: React.CSSProperties = {
  backgroundColor: '#fff'
}

export default function HomeView() {
  usePageTitle('Home');
  useBreadcrumb('Home');
  const {
    accountDailyStats,
    accountStats,
    fetchAccountDailyStats,
    fetchAccountStats,
    bidDailyStats,
    bidStats,
    fetchBidDailyStats,
    fetchBidStats,
    negotiationStats,
    fetchNegotiationStats,
    leadStats,
    fetchLeadStats,
  } = useStats();

  useEffect(() => {
    fetchAccountDailyStats();
    fetchAccountStats();
    fetchBidDailyStats();
    fetchBidStats();
    fetchNegotiationStats();
    fetchLeadStats();
  }, [
    fetchAccountDailyStats,
    fetchAccountStats,
    fetchBidDailyStats,
    fetchBidStats,
    fetchNegotiationStats,
    fetchLeadStats
  ])

  const stats: statsProps[] = [
    {
      key: '1',
      description: 'Usuários cadastrados:',
      number: accountStats?.number
    },
    {
      key: '2',
      description: 'Usuários cadastrados hoje:',
      number: accountDailyStats?.number
    },
    {
      key: '3',
      description: 'Ofertas cadastradas:',
      number: bidStats?.numberBids
    },
    {
      key: '4',
      description: 'Ofertas cadastradas hoje:',
      number: bidDailyStats?.numberBids
    },
    {
      key: '5',
      description: 'Leads feitas hoje:',
      number: leadStats?.numberLeads
    },
    {
      key: '6',
      description: 'Negociações feitas nos últimos 30 dias:',
      number: negotiationStats?.numberNegotiations
    },

  ]

  return (
    <>
      <Row gutter={[24, 24]}>
        {
          stats.map((item) => (
            <Col key={item.key} xs={{ span: 24 }} md={{ span: 8 }}>
              <Card bordered style={cardStyle}>
                <Statistic
                  title={item.description}
                  value={item.number}
                />
              </Card>
            </Col>
          ))
        }
      </Row>
      <Row gutter={[24, 24]} style={{marginTop: 20}}>
        <BidPercentageCharts />
      </Row>
      <Row gutter={[24, 24]} style={{marginTop: 20}}>
        <MarketDataCard />
      </Row>
    </>

  );
}