import React, { useState } from 'react';

import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  notification,
  Row,
  Select,
} from 'antd';
import Api from '../services/Api';
import moment from 'moment';

export default function CashFlowRevenuesView() {
  const [date, setDate] = useState('');

  const onChangeDate: DatePickerProps['onChange'] = (
    _date,
    dateString
  ) => {
    const formattedDate = moment(
      dateString,
      'DD/MM/YYYY'
    ).format('YYYY-MM-DD');
    setDate(formattedDate);
  };

  const onFinish = async (values: any) => {
    await Api.sendDataMarket(
      date,
      values.lastPrice,
      (values.origin = 'BOLSA'),
      values.symbol,
      values.variation
    )
      .then(() => {
        notification.success({
          message: 'Sucesso',
          description: 'Valor adicionado com sucesso',
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Erro!',
          description: `Erro código ${err.response.status} por favor entre em contato com o administrador!`,
        });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const product = [
    {
      id: '1',
      type: 'Milho',
    },
    {
      id: '2',
      type: 'Soja',
    },
    {
      id: '3',
      type: 'Sorgo',
    },
    {
      id: '4',
      type: 'Dólar',
    },
  ];

  return (
    <Form
      autoComplete={'off'}
      layout={'vertical'}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Form.Item
            label='Último Preço'
            name='lastPrice'
            rules={[
              {
                required: true,
                message: 'A entrada do preço é obrigatória',
              },
            ]}
          >
            <Input placeholder='Ex: 23.90' />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label='Produto'
            name='symbol'
            rules={[
              {
                required: true,
                message: 'Por favor selecione o produto',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Selecione o produto'
            >
              {product.map((user: any) => (
                <Select.Option
                  key={user.id}
                  value={user.type}
                >
                  {user.type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label='Variação'
            name='variation'
            rules={[
              {
                required: true,
                message:
                  'A entrada da variação é obrigatória!',
              },
            ]}
          >
            <Input placeholder='Ex: 0.22' />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label='Data da cotação'
            name='date'
            rules={[
              {
                required: true,
                message:
                  'A entrada da variação é obrigatória!',
              },
            ]}
          >
            <DatePicker
              format='DD/MM/YYYY'
              style={{ width: '100%' }}
              onChange={onChangeDate}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Row justify={'end'}>
            <Button type={'primary'} htmlType={'submit'}>
              Cadastrar
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
