import { useCallback, useState } from "react";
import { Account, AccountService, Bid, BidService, Lead, LeadService, Negotiation, NegotiationService } from "uai-graos-sdk";

export default function useBidStats() {
    const [bidDailyStats, setBidDailyStats] = useState<Bid.BidDashboard>()
    const [bidStats, setBidStats] = useState<Bid.BidDashboard>()
    const [bidPercentageBuy, setBidPercentageBuy] = useState<Bid.ProductsPercentage[]>()
    const [bidPercentageSell, setBidPercentageSell] = useState<Bid.ProductsPercentage[]>()
    const [accountDailyStats, setAccountDailyStats] = useState<Account.CardNumberModel>()
    const [accountStats, setAccountStats] = useState<Account.CardNumberModel>()
    const [leadStats, setLeadStats] = useState<Lead.LeadDailyDashboard>()
    const [negotiationStats, setNegotiationStats] = useState<Negotiation.NegotiationDashboard>()

    const fetchAccountDailyStats = useCallback(() => {
        AccountService.getNumberAccountsDaily().then(setAccountDailyStats)
    }, [])
    const fetchAccountStats = useCallback(() => {
        AccountService.getNumberAccounts().then(setAccountStats)
    }, [])
    const fetchBidDailyStats = useCallback(() => {
        BidService.getBidDailyNumber().then(setBidDailyStats)
    }, [])
    const fetchBidStats = useCallback(() => {
        BidService.getBidNumber().then(setBidStats)
    }, [])
    const fetchBidPercentageBuy = useCallback(() => {
        BidService.getBidPercentage('C').then(setBidPercentageBuy)
    }, [])
    const fetchBidPercentageSell = useCallback(() => {
        BidService.getBidPercentage('V').then(setBidPercentageSell)
    }, [])
    const fetchLeadStats = useCallback(() => {
        LeadService.getLeadDailyNumber().then(setLeadStats)
    }, []);
    const fetchNegotiationStats = useCallback(() => {
        NegotiationService.getDailyNegotiations().then(setNegotiationStats)
    }, []);

    return {
        accountDailyStats,
        accountStats,
        bidDailyStats,
        bidStats,
        bidPercentageBuy,
        bidPercentageSell,
        leadStats,
        negotiationStats,
        fetchBidDailyStats,
        fetchBidStats,
        fetchBidPercentageBuy,
        fetchBidPercentageSell,
        fetchAccountDailyStats,
        fetchAccountStats,
        fetchLeadStats,
        fetchNegotiationStats
    }

}