import { Tag, Tooltip } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { MerchantResponsible, MerchantResponsibleService } from "uai-graos-sdk";

export default function RepresentativeList() {

    const [pagination, setPagination] = useState<MerchantResponsible.Query>({
        page: 0,
        size: 10
    })
    const [merchantResponsibles, setMerchantResponsibles] = useState<MerchantResponsible.MerchantResponsible[]>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        responseApi(pagination)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function responseApi(query: MerchantResponsible.Query) {
        setLoading(true)
        await MerchantResponsibleService.getAllMerchantReponsibles(query)
            .then((resp: MerchantResponsible.Paged) => {
                const { content, totalElements } = resp
                setMerchantResponsibles(content)
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    total: totalElements,
                    showTotal: (total: number, range: any) =>
                        `${range[0]}-${range[1]} de ${total} registros`,
                    page: query.page,
                }));
            })
        setLoading(false)
    }

    const handlePaginationChange = (
        page: any,
        pageSize: any
    ) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: page - 1,
            size: pageSize,
        }));
        responseApi(
            {
                ...pagination,
                page: page - 1,
                size: pageSize
            }
        )
    };

    function filterTypes(type: string) {
        switch (type) {
            case 'REPRESENTATIVE':
                return 'Representante'
            case 'MANAGER':
                return 'Gerente'
            case 'DIRECTOR':
                return 'Diretor'
            case 'SUPERVISOR':
                return 'Supervisor'
            default:
                return 'Representante';
        }
    }

    const columns: ColumnsType<MerchantResponsible.MerchantResponsible> = [
        {
            key: '1',
            title: 'Nome',
            dataIndex: 'username',
        },
        {
            key: '2',
            title: 'E-Mail',
            dataIndex: 'email',
        },
        {
            key: '3',
            title: 'Cargo',
            dataIndex: 'type',
            render: (type: string) => filterTypes(type)
        },
        {
            key: '4',
            title: 'Comissão',
            dataIndex: 'percentageTax',
            render: (percentage: string) => `${percentage}%`
        },
        {
            key: '4',
            title: 'Região',
            dataIndex: 'regions',
            render: (regions: MerchantResponsible.MerchantResponsible['regions']) =>
                <Tooltip title={regions?.map(item => item.name).join(', ')}>
                    <Tag>
                        {regions?.slice(0, 1).map((item) => item.name)}
                    </Tag>
                    {
                        regions?.length && regions.length > 1 ?
                            <Tag>+{regions.length - 1}</Tag>
                            : null
                    }
                </Tooltip>
        } 
    ]

    return (
        <Table
            columns={columns}
            dataSource={merchantResponsibles}
            pagination={{
                ...pagination,
                size: 'default',
                current: pagination.page! + 1,
                onChange: handlePaginationChange,
            }}
            loading={loading}
        />
    )
}