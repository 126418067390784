import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Skeleton,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Api from '../services/Api';
import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import ConcractSellerForm from '../components/ContractSellerForm/ConcractSellerForm';
import ConcractBuyerForm from '../components/ContractBuyerForm/ContractBuyerForm';

export default function ContractCreateForm() {
  const [loading, setLoading] = useState(true);
  const [negotiationsIds, setNegotiationsIds] = useState(
    []
  );
  const [
    selectedNegotiationId,
    setSelectedNegotiationId,
  ] = useState('');
  const [
    selectedNegotiation,
    setSelectedNegotiation,
  ] = useState<any>(null);

  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    getNegotiationsId();
  }, []);

  const { Item } = Form;

  const requiredRule = [
    { required: true, message: 'O campo é obrigatório!' },
  ];

  const getNegotiationsId = async () => {
    return await Api.getAllNegotiationsIds()
      .then((resp) => {
        setNegotiationsIds(resp.data.negotiationsIds);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getSelectedNegotiation = async (values: any) => {
    await Api.getNegotiationsById(values.negotiationId)
      .then((req) => {
        const { data } = req;
        setSelectedNegotiationId(values.negotiationId);
        setSelectedNegotiation({
          buyerCep: data.buyerBid.account.cep,
          buyerCi: data.buyerBid.account.rg,
          buyerCiDispatched:
            data.buyerBid.account.issuingAgency,
          buyerCity: data.buyerBid.account.city,
          buyerComplement: data.buyerBid.account.complement,
          buyerDeadlineDelivery: '',
          buyerDeliveryPlace: '',
          buyerDocument: data.buyerBid.account.cpfCnpj,
          buyerGrainType: data.buyerBid.product,
          buyerName: data.buyerBid.account.username,
          buyerDistrict: data.buyerBid.account.district,
          buyerAddressNumber:
            data.buyerBid.account.addressNumber,
          buyerQuantity: data.quantity,
          buyerResponsibleForDelivery: '',
          buyerState: data.buyerBid.account.state,
          buyerStateRegistration:
            data.buyerBid.account.stateRegistration,
          buyerStreet: data.buyerBid.account.street,
          buyerVintage: '',
          sellerCep: data.sellerBid.account.cep,
          sellerCi: data.sellerBid.account.rg,
          sellerCiDispatched:
            data.sellerBid.account.issuingAgency,
          sellerCity: data.sellerBid.account.city,
          sellerComplement:
            data.sellerBid.account.complement,
          sellerDeadlineDelivery: '',
          sellerDeliveryPlace: '',
          sellerDocument: data.sellerBid.account.cpfCnpj,
          sellerGrainType: data.sellerBid.product,
          sellerName: data.sellerBid.account.username,
          sellerDistrict: data.sellerBid.account.district,
          sellerAddressNumber:
            data.sellerBid.account.addressNumber,
          sellerQuantity: data.quantity,
          sellerResponsibleForDelivery: '',
          sellerState: data.sellerBid.account.state,
          sellerStateRegistration:
            data.sellerBid.account.stateRegistration,
          sellerStreet: data.sellerBid.account.street,
          sellerVintage: '',
        });
      })
      .catch((err) => {
        if (err.response) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
          });
        } else if (err.request) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
        notification.error({
          message: 'Erro!',
          description: `Por favor entre em contato com seu administrador! Erro código: ${err.config}`,
        });
      });
  };
  const onFinishContract = async (values: any) => {
    await Api.generateSellerContract(
      selectedNegotiationId,
      values.sellerCep,
      values.sellerCi,
      values.sellerCiDispatched,
      values.sellerDeadlineDelivery,
      values.sellerDeliveryPlace,
      values.sellerDocument,
      values.sellerRuralBackground === 'Na nota',
      values.sellerGrainType,
      values.sellerAddressNumber,
      values.sellerMaritalStatus,
      values.sellerName,
      values.sellerQuantity,
      values.sellerResponsibleForDelivery,
      values.sellerRuralBackground,
      values.sellerStateRegistration,
      values.sellerVintage,
      values.sellerCity,
      values.sellerComplement,
      values.sellerDistrict,
      values.sellerState,
      values.sellerStreet
    )
      .then(() =>
        notification.success({
          message: 'Sucesso!',
          description: 'Contrato criado com sucesso!',
        })
      )
      .catch((err) => {
        if (err.response) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
          });
        } else if (err.request) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
        notification.error({
          message: 'Erro!',
          description: `Por favor entre em contato com seu administrador! Erro código: ${err.config}`,
        });
      });
  };

  if (loading) {
    return <Skeleton />;
  }
  return (
    <>
      <Form
        layout={'vertical'}
        onFinish={getSelectedNegotiation}
      >
        <Row gutter={50}>
          <Col span={12}>
            <Item
              rules={requiredRule}
              label='Negociação'
              name='negotiationId'
            >
              <Select
                showSearch
                value={negotiationsIds}
                placeholder='Selecione o ID da negociação'
              >
                {negotiationsIds.map((negotiation: any) => (
                  <Select.Option
                    key={negotiation}
                    value={negotiation}
                    data={negotiation}
                  >
                    {negotiation}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 20, marginBottom: 20 }}>
          <Button type='primary' htmlType='submit'>
            Pesquisar
          </Button>
        </Row>
      </Form>
      
      {selectedNegotiation ? (
        <Form
          layout={'vertical'}
          initialValues={selectedNegotiation}
          onFinish={onFinishContract}
        >
          <Row gutter={50}>
            <ConcractSellerForm showForm={showForm} />
            <ConcractBuyerForm showForm={showForm} />
          </Row>

          <Row justify='center'>
            {showForm ? (
              <UpOutlined
                onClick={() => setShowForm(!showForm)}
              />
            ) : (
              <DownOutlined
                onClick={() => setShowForm(!showForm)}
              />
            )}
          </Row>

          <Row>
            <Col md={12} lg={8}>
              <Button type='primary' htmlType='submit'>
                Criar contrato
              </Button>
            </Col>
          </Row>
        </Form>
      ) : null}
    </>
  );
}
