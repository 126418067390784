import { CustomError, Service } from 'uai-graos-sdk';
import AuthService from './Authorization.service';
import { notification } from 'antd';


const baseUrl = process.env.REACT_APP_API_BASE_URL;
if (baseUrl) {
  Service.setBaseUrl(`${baseUrl}/v1`);
}

Service.setRequestInterceptors(async (request) => {
  const accessToken = AuthService.getAccessToken();
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return request;
}
)

Service.setResponseInterceptors(async (response) => response,
  (error: CustomError) => {
    if (error.status === 401 || error.status === 403) {
      AuthService.imperativelySendToLoginScreen();
      notification.error({
        message: 'Erro!',
        description: error?.message,
      });
      return Promise.reject(error?.message)
    }
    return Promise.reject(error);
  }
);