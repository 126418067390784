import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Descriptions,
  Typography,
  Tag,
  Avatar,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Api from '../services/Api';
import Profile from './../../assets/Profile.png';
import { Link } from 'react-router-dom';
import CashInput from '../components/CashInput/CashInput';

export default function CounterBidCreateForm() {
  const [bids, setBids] = useState([]);
  const [selectedBid, setSelectedBid] = useState<any>(null);
  const [bidsNearby, setBidsNearby] = useState<any>(null);
  const [
    selectedBidNegotiate,
    setSelectedBidNegotiate,
  ] = useState<any>(null);
  const { Item } = Form;

  useEffect(() => {
    getBids();
  }, []);

  const requiredRule = [
    { required: true, message: 'O campo é obrigatório!' },
  ];

  const getBidNegotiateDetails = async (bidId: any) => {
    return await Api.getOrderById(bidId)
      .then((res) => setSelectedBidNegotiate(res.data))
      .catch((err) => {
        if (err.response) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
          });
        } else if (err.request) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
        notification.error({
          message: 'Erro!',
          description: `Por favor entre em contato com seu administrador! Erro código: ${err}`,
        });
      });
  };

  const getBidDetails = async (bidId: any) => {
    return await Api.getOrderById(bidId)
      .then((res) => setSelectedBid(res.data))
      .catch((err) => {
        if (err.response) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
          });
        } else if (err.request) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
        notification.error({
          message: 'Erro!',
          description: `Por favor entre em contato com seu administrador! Erro código: ${err.config}`,
        });
      });
  };

  async function getBidsNearby(bidId: any) {
    await Api.getBidsNearby(bidId)
      .then((resp) => {
        const { ordersNearby } = resp.data;
        setBidsNearby(ordersNearby);
        console.log(resp.data.ordersNearby);
      })
      .catch((err) => {
        if (err.response) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
          });
        } else if (err.request) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
        notification.error({
          message: 'Erro!',
          description: `Por favor entre em contato com seu administrador! Erro código: ${err.config}`,
        });
      });
  }

  function getBids() {
    return Api.getAllBids()
      .then((resp) => setBids(resp.data.content))
      .catch((err) => console.log(err));
  }

  async function onFinishCounterBid(values: any) {
    function filterPrice(price: string) {
      if (!price) return;
      const replacePrice = price.slice(3);
      return parseFloat(replacePrice);
    }

    const price = filterPrice(values.price);

    await Api.createCounterBid(
      values.bidId,
      values.bidNegotiateId,
      price,
      values.quantity
    )
      .then((res) => {
        const { status } = res.data;

        if (status === 'APPROVED') {
          notification.success({
            message: 'Sucesso!',
            description: 'A contraproposta foi aceita!',
          });
          //Mandar para a tela de negociação
        } else {
          notification.error({
            message: 'Recusada!',
            description:
              'A contraproposta foi recusada, por favor tente novamente com outro valor!',
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
          });
        } else if (err.request) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
        notification.error({
          message: 'Erro!',
          description: `Por favor entre em contato com seu administrador! Erro código: ${err.config}`,
        });
      });
  }

  return (
    <Form layout='vertical' onFinish={onFinishCounterBid}>
      <Row gutter={24}>
        <Col lg={12} md={24}>
          <Item
            rules={requiredRule}
            label='Oferta:'
            name='bidId'
          >
            <Select
              onChange={(e) => [
                getBidDetails(e),
                getBidsNearby(e),
              ]}
            >
              {bids.map((bids: any) => (
                <Select.Option
                  value={bids.id}
                  data={bids}
                  key={bids.id}
                >
                  {bids.id}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col lg={12} md={24}>
          <Item
            rules={requiredRule}
            label='Oferta Contraparte:'
            name='bidNegotiateId'
          >
            <Select
              onChange={(e) => getBidNegotiateDetails(e)}
            >
              {bidsNearby?.map((bids: any) => (
                <Select.Option
                  value={bids.id}
                  data={bids}
                  key={bids.id}
                >
                  {bids.id}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col lg={12} md={24}>
          <Item
            rules={requiredRule}
            label='Quantidade:'
            name='quantity'
          >
            <Input />
          </Item>
        </Col>
        <Col lg={12} md={24}>
          <Item
            rules={requiredRule}
            label='Preço:'
            name='price'
          >
            <CashInput />
          </Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={24} style={{ marginTop: 24 }}>
        {selectedBid ? (
          <Col xs={24} lg={12}>
            <Typography.Title level={3}>
              Oferta
            </Typography.Title>
            <Descriptions column={1} bordered size='small'>
              <Descriptions.Item label={'ID da oferta'}>
                {selectedBid?.id}
              </Descriptions.Item>
              <Descriptions.Item label={'Nome'}>
                <Typography.Text strong>
                  <Avatar
                    size={20}
                    style={{ marginRight: 10 }}
                    src={Profile}
                  />
                  <Link
                    to={`/users/${selectedBid?.account.id}`}
                  >
                    {selectedBid?.account.username}
                  </Link>
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label={'Telefone'}>
                {selectedBid?.account.phoneNumber}
              </Descriptions.Item>
              <Descriptions.Item label={'E-Mail'}>
                {selectedBid?.account.email}
              </Descriptions.Item>
              <Descriptions.Item label={'Tipo de oferta'}>
                {selectedBid?.side === 'V'
                  ? 'Venda'
                  : 'Compra'}
              </Descriptions.Item>
              <Descriptions.Item
                label={'Aceita Contraproposta?'}
              >
                {selectedBid?.acceptCounterBid ? (
                  <Tag color='cyan'>Sim</Tag>
                ) : (
                  <Tag color='red'>Não</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={'Quantidade'}>
                {selectedBid?.quantity}
              </Descriptions.Item>
              <Descriptions.Item label={'Produto'}>
                {selectedBid?.product}
              </Descriptions.Item>
              <Descriptions.Item label={'Preço'}>
                <Tag color='green'>
                  R$ {selectedBid?.price}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label={'Tipo de frete'}>
                {selectedBid?.freight}
              </Descriptions.Item>
              <Descriptions.Item
                label={'Tipo de pagamento'}
              >
                {selectedBid?.payment}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        ) : null}
        {selectedBidNegotiate ? (
          <Col xs={24} lg={12}>
            <Typography.Title level={3}>
              Oferta da contraparte
            </Typography.Title>
            <Descriptions column={1} bordered size='small'>
              <Descriptions.Item label={'ID da oferta'}>
                {selectedBidNegotiate?.id}
              </Descriptions.Item>
              <Descriptions.Item
                label={'Nome da contraparte'}
              >
                <Typography.Text strong>
                  <Avatar
                    size={20}
                    style={{ marginRight: 10 }}
                    src={Profile}
                  />
                  <Link
                    to={`/users/${selectedBidNegotiate?.account.id}`}
                  >
                    {selectedBidNegotiate?.account.username}
                  </Link>
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label={'Telefone'}>
                {selectedBidNegotiate?.account.phoneNumber}
              </Descriptions.Item>
              <Descriptions.Item label={'E-Mail'}>
                {selectedBidNegotiate?.account.email}
              </Descriptions.Item>
              <Descriptions.Item label={'Tipo de oferta'}>
                {selectedBidNegotiate?.side === 'V'
                  ? 'Venda'
                  : 'Compra'}
              </Descriptions.Item>
              <Descriptions.Item
                label={'Aceita Contraproposta?'}
              >
                {selectedBidNegotiate?.acceptCounterBid ? (
                  <Tag color='cyan'>Sim</Tag>
                ) : (
                  <Tag color='red'>Não</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={'Quantidade'}>
                {selectedBidNegotiate?.quantity}
              </Descriptions.Item>
              <Descriptions.Item label={'Produto'}>
                {selectedBidNegotiate?.product}
              </Descriptions.Item>
              <Descriptions.Item label={'Preço'}>
                <Tag color='green'>
                  R$ {selectedBidNegotiate?.price}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label={'Tipo de frete'}>
                {selectedBidNegotiate?.freight}
              </Descriptions.Item>
              <Descriptions.Item
                label={'Tipo de pagamento'}
              >
                {selectedBidNegotiate?.payment}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        ) : null}
        <Col lg={8} md={24} style={{ marginTop: 10 }}>
          <Button type='primary' htmlType='submit'>
            Criar proposta
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
