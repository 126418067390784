import styled from 'styled-components';

export const TitleBid = styled.h3`
  font-weight: bolder;
  text-align: center;
`;
export const ModalText = styled.p`
  margin: 0;
  font-size: 14px;
`;
export const Divider = styled.hr`
  height: 12px;
  border: 0;
  box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.5);
`;
export const SelectContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;
