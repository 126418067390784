import React, { useEffect } from 'react'
import { Card, Col } from 'antd'
import useMarketData from '../../../core/hooks/useMarketData'
import styled from 'styled-components'

export default function MarketDataCard() {
    const { fetchMarketData, marketData } = useMarketData()

    useEffect(() => {
        fetchMarketData()
    }, [fetchMarketData])


    return (
        <>
            {
                marketData?.map((item) => (
                    <Col xs={{ span: 24 }} md={{ span: 6 }}>
                        <MarketDataWrapper>
                            <Symbol>
                                {item.marketDataId?.symbol}
                            </Symbol>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Price>
                                    R$ {item.lastPrice?.toString().replace('.', ',')}
                                </Price>
                                <Variation>
                                    {item.variation}%
                                </Variation>
                            </div>
                        </MarketDataWrapper>
                    </Col>
                ))
            }
        </>
    )
}

const Variation = styled.p`
    margin-left: 10px;
`
const Symbol = styled.h3`
    color: #8c8c8c;
`
const Price = styled.p`
    color: #000;
    font-size: 20px;
`
const MarketDataWrapper = styled(Card)`
    background: #fff;
`