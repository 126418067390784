import { Account, AccountService } from 'uai-graos-sdk';
import { useCallback, useState } from 'react';

export default function useUser() {
  const [user, setUser] = useState<Account.Account>();

  const fetchUser = useCallback(async (userId: string) => {
    await AccountService.getAccountById(userId)
      .then(setUser)
  }, []);


  return {
    user,
    fetchUser
  };
}
