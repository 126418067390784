/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  notification,
} from 'antd';
import {
  EditOutlined, PoweroffOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Account, Bid, BidService } from 'uai-graos-sdk';
import { ColumnsType } from 'antd/lib/table';
import { format as formatPhoneNumber } from 'telefone'

function BidsList() {
  const { search } = useLocation();
  const { replace } = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const [pagination, setPagination] = useState<Bid.Query>({
    page: Number(searchParams.get('page')) || 0,
    size: 10,
    side: 'ALL',
    sort: ["updatedAt", "DESC"],
    product: undefined,
    username: '',
    virtual: 'false'
  });
  const [bids, setBids] = useState<Bid.Bid[]>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const productsList = [
    { value: 'Milho', label: 'Milho' },
    { value: 'Soja', label: 'Soja' },
    { value: 'Sorgo', label: 'Sorgo' },
  ]
  const sideList = [
    { value: 'ALL', label: 'Todos (Compra e Venda)' },
    { value: 'C', label: 'Compra' },
    { value: 'V', label: 'Venda' },
  ]
  const virtualList = [
    { value: "false", label: 'Sim' },
    { value: "true", label: 'Não' },
    { value: false, label: 'Mostrar todas' },
  ]
  const modalityList = [
    { value: false, label: 'Todas (Disponível e Futuro)' },
    { value: "Disponível", label: 'Disponível' },
    { value: "Futuro", label: 'Futuro' },
  ]

  useEffect(() => {
    responseApi(pagination);
    searchParams.set('page', `${pagination.page}`)
    replace({ search: searchParams.toString() });
  }, []);

  function responseApi(
    query: Bid.Query
  ) {
    setLoading(true);
    BidService.getAllBids(query)
      .then((res) => {
        const { content, totalElements } = res;
        setBids(content);
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalElements,
          showTotal: (total: number, range: any) =>
            `${range[0]}-${range[1]} de ${total} registros`,
          page: query.page,
        }));
        setLoading(false);
      })
  }

  async function handleInativeBid(record: Bid.Bid) {
    if (!record.virtual) {
      await BidService.inactiveBid(record.id!)
        .then(() => {
          responseApi(pagination)
          notification.success({
            message: 'Sucesso!',
            description: 'A oferta foi desativada',
          });
        })
        .catch(console.log)
    } else {
      await BidService.restoreBid(record.id!)
        .then(() => {
          responseApi(pagination)
          notification.success({
            message: 'Sucesso!',
            description: 'A oferta foi ativada',
          });
        })
        .catch(console.log)
    }
  }

  const handlePaginationChange = (
    page: any,
    pageSize: any
  ) => {
    searchParams.set('page', `${page - 1}`)
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: page - 1,
      size: pageSize,
    }));
    replace({ search: searchParams.toString() });
    responseApi(
      {
        ...pagination,
        page: page - 1,
        size: pageSize
      }
    )
  };

  const columns: ColumnsType<Bid.Bid> = [
    {
      key: '1',
      title: 'Código',
      dataIndex: 'sku',
    },
    {
      key: '2',
      title: 'Usuário',
      dataIndex: 'username',
    },
    {
      key: '17',
      title: 'Tipo do usuário',
      dataIndex: 'account',
      render: (account: Account.Account) => account.typePerson === 'BUYER'
        ? 'Comprador'
        : account.typePerson === 'SELLER'
          ? 'Vendedor'
          : account.typePerson === 'BROKER'
            ? 'Corretor'
            : 'Armazém'
    },
    {
      key: '3',
      title: 'Telefone',
      dataIndex: 'account',
      render: (account: Account.Account) => formatPhoneNumber(account.phoneNumber)
    },
    {
      key: '4',
      title: 'Tipo',
      dataIndex: 'side',
      render: (_: any, { side }: any) => (
        <Tag
          color={side === 'V' ? 'red' : 'blue'}
          key={side}
        >
          {side === 'C' ? 'COMPRA' : 'VENDA'}
        </Tag>
      ),
    },
    {
      key: '5',
      title: 'Produto',
      dataIndex: 'product',
    },
    {
      key: '6',
      title: 'Preço',
      dataIndex: 'price',
      render: (price: number) => (
        <div style={{ minWidth: '65px' }}>
          R$ {price.toFixed(2).replace('.', ',')}
        </div>
      )
    },
    {
      key: '7',
      title: 'Quantidade',
      dataIndex: 'quantity',
    },
    {
      key: 'modality',
      title: 'Modalidade',
      dataIndex: 'modality',
    },
    {
      key: '8',
      title: 'Cidade',
      dataIndex: 'address',
      render: (address: any) => address?.city
    },
    {
      key: '9',
      title: 'Estado',
      dataIndex: 'address',
      render: (address: any) => address?.state
    },

    {
      key: '10',
      title: 'Data de criação',
      dataIndex: 'createdAt',
      render: (date: number) => {
        return (
          <span>
            {moment(date).utc().format('DD/MM/YYYY')}
          </span>
        );
      },
    },
    {
      key: '11',
      title: 'Data de atualização',
      dataIndex: 'updatedAt',
      render: (date: number) => {
        return (
          <span>
            {moment(date).utc().format('DD/MM/YYYY')}
          </span>
        );
      },
    },
    {
      key: '12',
      title: 'Tipo de Pagamento',
      dataIndex: 'payment',
      render: (payment: string) => (
        payment ?? 'N/A'
      )
    },
    {
      key: '13',
      title: 'Ativo?',
      dataIndex: 'virtual',
      render: (status: boolean) => (
        !status ? 'Sim' : 'Não'
      )
    },
    {
      title: 'Ações',
      key: '14',
      dataIndex: 'id',
      render: (id: string, record: any) => (
        <Space size='middle'>
          <Tooltip title='Editar oferta'>
            <Link to={`bids/edit/${id}`}>
              <EditOutlined />
            </Link>
          </Tooltip>
          <Tooltip title={`${record.virtual ? 'Ativar' : 'Desativar'} oferta`}>
            <PoweroffOutlined style={{ color: `${record.virtual ? '#ff7875' : '#4096ff'}` }} onClick={() => handleInativeBid(record)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onFinishFilter = (values: Bid.Query) => {
    const { product, virtual, username, side, modality } = values
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 0,
      product,
      username,
      side,
      virtual,
      modality
    }));
    responseApi(
      {
        ...pagination,
        page: 0,
        product,
        username,
        side,
        virtual,
        modality
      }
    )
  }

  const onResetFilter = () => {
    setPagination({
      page: 0,
      size: 10,
      side: 'ALL',
      virtual: 'false',
    });
    responseApi(
      {
        page: 0,
        size: 10,
        side: 'ALL',
        virtual: 'false',
      }
    )
  }

  return (
    <div>
      <Form
        form={form}
        onFinish={onFinishFilter}
        onReset={onResetFilter}
      >
        <Row gutter={16}>
          <Col span={6} xs={24} lg={6}>
            <Form.Item name='username' label='Pessoa'>
              <Input placeholder='Insira o nome da Pessoa' />
            </Form.Item>
          </Col>
          <Col span={6} xs={24} lg={6}>
            <Form.Item name='product' label='Produto'>
              <Select
                options={productsList}
                allowClear
                placeholder='Selecione o produto'
              />
            </Form.Item>
          </Col>
          <Col span={6} xs={24} lg={6}>
            <Form.Item initialValue={'ALL'} name='side' label='Tipo'>
              <Select
                options={sideList}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6} xs={24} lg={6}>
            <Form.Item initialValue='false' name='virtual' label='Ativo?'>
              <Select
                options={virtualList}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6} xs={24} lg={6}>
            <Form.Item initialValue={false} name='modality' label='Modalidade'>
              <Select
                options={modalityList}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Col>
            <Button
              type='primary'
              danger
              htmlType="reset"
              style={{
                marginRight: 10
              }}
            >
              Limpar Filtros
            </Button>
            <Button
              type='primary'
              htmlType="submit"
            >
              Pesquisar
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >

        <Modal
          open={showEditModal}
          onCancel={() => setShowEditModal(false)}
        />
        <Table
          rowKey={'id'}
          pagination={{
            ...pagination,
            size: 'default',
            current: pagination.page! + 1,
            onChange: handlePaginationChange,
          }}
          columns={columns}
          dataSource={bids}
          loading={loading}
        />
      </div>
    </div>
  );
}
export default BidsList;
