import { useCallback, useState } from 'react';
import Api from '../../app/services/Api';

export default function usePosts() {
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [posts, setPosts] = useState<any>();

  const fetchUserPosts = useCallback(
    async (userId: string) => {
      setLoadingFetch(true);
      try {
        const res = await Api.getAccountTimeline(userId)
        setPosts(res.data);
      } finally {
        setLoadingFetch(false);
      }
    },
    []
  );

  return {
    fetchUserPosts,
    posts,
    loadingFetch,
  };
}
