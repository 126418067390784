import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Bid, BidService } from 'uai-graos-sdk';
import { notification } from 'antd';

export default function useBid() {
  const [bid, setBid] = useState<Bid.Bid>();
  const [isLoading, setIsLoading] = useState<boolean>(
    false
  );
  const history = useHistory();

  const fetchBid = useCallback(
    (bidId: string) => {
      BidService.getBidById(bidId)
        .then(setBid)
        .catch(() => {
          notification.error({
            style: { zIndex: 100 },
            message: 'Oferta não encontrada!',
          });
          history.push('/bids');
        });
    },
    [history]
  );

  function convertPrice(price: number | undefined) {
    if (!price) {
      return price;
    }
    const priceString = String(price);
    const transform = priceString.replace('R$', '');
    const priceNumber = Number(transform);

    return priceNumber;
  }

  const bidFormat = useCallback(
    (bid: Bid.BidUpdateInput) => {
      return {
        ...bid,
        price: convertPrice(bid.price),
        bestPrice: convertPrice(bid.bestPrice),
        city: bid.city?.split(' -')[0],
      };
    },
    []
  );

  async function handleBidUpdate(
    bidId: string,
    bids: Bid.Bid
  ) {
    setIsLoading(true);
    BidService.duplicateBid(bidId, bidFormat(bids)).then(
      () => {
        notification.success({
          message: 'A oferta foi atualizada!',
        });
        setIsLoading(false);
        history.push('/bids');
      }
    );
    setIsLoading(false);
  }
  return {
    bid,
    fetchBid,
    isLoading,
    handleBidUpdate,
  };
}
