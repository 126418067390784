import axios, { AxiosResponse } from 'axios';

const APP_BASE_URL = process.env.REACT_APP_BACK_OFFICE_BASE_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const authServer = axios.create({
  baseURL: API_BASE_URL,
});
authServer.interceptors.response.use(undefined, async () => {
  return Promise.reject('Usuário/senha inválidos');
});

export interface OAuthAuthorizationTokenResponse {
  access_token: string;
  token_type: 'bearer' | string;
  expires_in: number;
  accountId: string;
  rule: string
}

export default class AuthService {


  public static async getFirstAccessTokens(config: {
    login: string,
    password: string
  }) {
    return authServer.post<OAuthAuthorizationTokenResponse>('/auth/login', config, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(async (resp) => {
      const { rule } = resp.data
      switch (rule) {
        case "ADMIN":
          this.sucessfulLogin(rule, resp)
          break;
        case "BACK_OFFICE":
          this.sucessfulLogin(rule, resp)
          break;

        default:
          window.localStorage.clear()
          Promise.reject('Você não tem permissão para acessar o sistema')
          break;
      }
      return;
    }
    )

  }

  public static async sucessfulLogin(rule: string, resp: AxiosResponse<OAuthAuthorizationTokenResponse>) {
    this.setAccessToken(resp.data.access_token)
    this.setAccount(resp.data.accountId)
    window.location.href = `${APP_BASE_URL}`
  }

  public static async imperativelySendToLoginScreen() {
    window.location.href = `${APP_BASE_URL}/login`;
  }

  public static getAccessToken() {
    return window.localStorage.getItem('access_token');
  }
  public static setAccessToken(token: string) {
    return window.localStorage.setItem('access_token', token);
  }

  public static getAccount() {
    return window.localStorage.getItem('account');
  }
  public static setAccount(id: string) {
    return window.localStorage.setItem('account', id);
  }

  public static getCodeVerifier() {
    return window.localStorage.getItem('codeVerifier');
  }
  public static setCodeVerifier(getCodeVerifier: string) {
    return window.localStorage.setItem('codeVerifier', getCodeVerifier);
  }
}