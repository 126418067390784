import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../store/UI.slice';

export default function useBreadcrumb(
  newBreadcrumb?: string
) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (newBreadcrumb)
      dispatch(setBreadcrumb(newBreadcrumb.split('/')));
  }, [dispatch, newBreadcrumb]);

}
