import {
  Button,
  Form,
  Input,
  Upload,
  UploadProps,
  message,
  notification,
} from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../services/Api';
import { InboxOutlined } from '@ant-design/icons';

export default function ConcractSignForm() {
  const params = useParams<{ id: any }>();
  const [document, setDocument] = useState('');

  const { Dragger } = Upload;

  const BASE_API = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('token');

  const accountId = localStorage.getItem('accountId');


  const props: UploadProps = {
    name: 'document',
    multiple: false,
    action: `${BASE_API}/v1/documents`,
    accept: '.pdf,.docx',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      documentType: 'CONTRACT',
    },
    onChange(info) {
      const { status, response } = info.file;
      if (status === 'done') {
        setDocument(response.url);

        message.success(
          `${info.file.name} arquivo carregado com sucesso!`
        );
      } else if (status === 'error') {
        message.error(
          `${info.file.name} falha ao carregar o arquivo!`
        );
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onFinishContract = async (values: any) => {
    await Api.signContract(params.id, accountId, document)
      .then(() => {
        notification.success({
          message: 'Sucesso',
          description: 'Contrato enviado com sucesso!',
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 412) {
            notification.error({
              message: 'Erro!',
              description:
                'Já foi criado um contrato para essa negociação',
            });
          } else {
            notification.error({
              message: 'Erro!',
              description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
            });
          }
        } else if (err.request) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
      });
  };

  return (
    <Form layout='vertical' onFinish={onFinishContract}>
      <Form.Item
        label='Id do contrato:'
        rules={[
          { required: true, message: 'Campo obrigatório!'},
        ]}
      >
        <Input value={params.id} />
      </Form.Item>
      <Form.Item
        rules={[
          { required: true, message: 'Campo obrigatório!'},
        ]}
      >
        <Dragger {...props}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            Clique ou arraste um arquivo para fazer upload
          </p>
          <p className='ant-upload-hint'>
            Arquivos .pdf e .docx
          </p>
        </Dragger>
      </Form.Item>
      <Button type='primary' htmlType='submit'>
        Enviar
      </Button>
    </Form>
  );
}
