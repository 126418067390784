import { Modal, Select } from 'antd';
import moment from 'moment';
import {
  Divider,
  ModalText,
  SelectContainer,
  TitleBid,
} from './NegotiationModal.styles';

export default function NegotiationModal({
  visible,
  onOk,
  onCancel,
  title,
  negotiationId,
  negotiationPrice,
  negotiationProduct,
  bidId,
  bidUser,
  bidSide,
  bidCreatedAt,
  bidModality,
  bidPhoneNumber,
  bidPrice,
  bidNegotiateId,
  bidNegotiateUser,
  bidNegotiateSide,
  bidNegotiateCreatedAt,
  bidNegotiateModality,
  bidNegotiatePhoneNumber,
  bidNegotiatePrice,
  status,
  defaultStatus,
  changeStatus,
}: any) {
  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      title={title}
    >
      <SelectContainer>
        <ModalText>Status da Negociação:</ModalText>
        <Select
        defaultValue={defaultStatus}
          value={status}
          style={{ width: 120, marginLeft: 10 }}
          onChange={changeStatus}
          options={[
            { value: 'AWAITING', label: 'Em espera' },
            { value: 'FINISHED', label: 'Finalizado' },
            { value: 'CANCELLED', label: 'Cancelado' },
          ]}
        />
      </SelectContainer>
      <ModalText>
        ID da negociação: {negotiationId}
      </ModalText>
      <ModalText>
        Preço da negociação: R$ {negotiationPrice}
      </ModalText>
      <ModalText>Produto: {negotiationProduct}</ModalText>
      <TitleBid>
        Oferta de {bidSide === 'V' ? 'Venda' : 'Compra'}
      </TitleBid>
      <Divider />
      <ModalText>ID da Oferta: {bidId}</ModalText>
      <ModalText>Nome do negociante: {bidUser}</ModalText>
      <ModalText>
        Tipo de oferta:{' '}
        {bidSide === 'V' ? 'Venda' : 'Compra'}
      </ModalText>
      <ModalText>
        Data de criação:{' '}
        {moment(bidCreatedAt).utc().format('DD/MM/YYYY')}
      </ModalText>
      <ModalText>Modalidade: {bidModality}</ModalText>
      <ModalText>
        Telefone de contato: {bidPhoneNumber}
      </ModalText>
      <ModalText>Preço da oferta: R$ {bidPrice}</ModalText>
      <TitleBid>
        Oferta de{' '}
        {bidNegotiateSide === 'V' ? 'Venda' : 'Compra'}
      </TitleBid>
      <Divider />
      <ModalText>ID da Oferta: {bidNegotiateId}</ModalText>
      <ModalText>
        Nome do negociante: {bidNegotiateUser}
      </ModalText>
      <ModalText>
        Tipo de oferta:{' '}
        {bidNegotiateSide === 'V' ? 'Venda' : 'Compra'}
      </ModalText>
      <ModalText>
        Data de criação:{' '}
        {moment(bidNegotiateCreatedAt)
          .utc()
          .format('DD/MM/YYYY')}
      </ModalText>
      <ModalText>
        Modalidade: {bidNegotiateModality}
      </ModalText>
      <ModalText>
        Telefone de contato: {bidNegotiatePhoneNumber}
      </ModalText>
      <ModalText>
        Preço da oferta: R$ {bidNegotiatePrice}
      </ModalText>
    </Modal>
  );
}
