/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Spin, Table, Tag } from "antd";
import useBarter from "../../core/hooks/useBarter";
import { Account, AccountService, Barter } from "uai-graos-sdk";
import useUser from "../../core/hooks/useUser";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";


export default function BarterList() {

  const [form] = Form.useForm()

  const { barterQuotes, fetchBarterQuotes, loading } = useBarter()
  const { fetchUser, user } = useUser()

  const [pagination, setPagination] = useState<Barter.Query>({
    page: 0,
    size: 10,
    accountId: ''
  });
  const [accountsPagination, setAccountsPagination] = useState<Account.Query>({
    page: 0,
    size: 10,
    username: ''
  });
  const [accountsList, setAccountsList] = useState<Account.PageAccount>();

  useEffect(() => {
    AccountService.getAllAccounts(accountsPagination).then(setAccountsList)
    fetchBarterQuotes(pagination)
  }, [])


  const onFinishFilter = async () => {
    fetchBarterQuotes({ accountId: user?.id })
  }
  const onResetFilter = async () => {
    setPagination({
      page: 0,
      size: 10,
      accountId: ''
    });
    fetchBarterQuotes({
      page: 0,
      size: 10,
      accountId: ''
    })
  }

  const handlePaginationChange = (
    page: any,
    pageSize: any
  ) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: page - 1,
      size: pageSize,
    }));
    fetchBarterQuotes(
      {
        ...pagination,
        page: page - 1,
        size: pageSize
      }
    )
  };

  const columns = [
    {
      title: 'ID da cotação',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome do criador',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Tipo de Pessoa',
      dataIndex: 'typePerson',
      key: 'typePerson',
      render: (typePerson: string) =>
        <Tag
          style={{ width: 120, textAlign: 'center' }}
          color={
            typePerson === 'PF' ? 'green' : 'blue'

          }
          key={typePerson}
        >
          {
            typePerson === 'PF' ?
              'PESSOA FÍSICA' :
              'PESSOA JURÍDICA'
          }
        </Tag>

    },
    {
      title: 'Cidade - Estado',
      dataIndex: 'address',
      key: 'city',
      render: (address: Barter.BarterQuotation['address']) => `${address?.city} - ${address?.state}`
    },
    {
      title: 'Documento',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: 'Grão',
      dataIndex: 'production',
      key: 'production',
    },
    {
      title: 'Área',
      dataIndex: 'area',
      key: 'area',
      render: (area: string) => `${area} ha`
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) =>
        <Tag
          style={{ width: 90, textAlign: 'center' }}
          color={
            status === 'ACTIVE' ? 'green' : 'red'

          }
          key={status}
        >
          {status === 'ACTIVE'
            ? 'ATIVO'
            : 'CANCELADO'}
        </Tag>

    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'actions',
      render: (id: string) =>
        <Link to={`/barter/${id}`}>
          <EyeOutlined />
        </Link>
    },
  ];

  const onAccountChange = (value: string, option: any) => {
    fetchUser(option.key)
  }
  const fetchAccounts = (query: Account.Query) => {
    AccountService.getAllAccounts(query).then(setAccountsList)
  }

  const onAccountSearch = (value: string) => {
    setAccountsPagination({ ...pagination, username: value })
    fetchAccounts(pagination)
  }

  return (
    <div>
      <Form
        form={form}
        onFinish={onFinishFilter}
        onReset={onResetFilter}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name='user' label='Nome do criador'>
              <Select
                onChange={onAccountChange}
                placeholder='Pesquisa por nome:'
                showSearch
                onSearch={onAccountSearch}
                notFoundContent={loading ? <Spin size="small" /> : null}
              >
                {accountsList?.content?.map((a: Account.Account) => (
                  <Select.Option
                    key={a.id}
                    value={`${a.username} (${a.email})`}
                  >
                    {`${a.username} (${a.email})`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Col>
            <Button
              type='primary'
              danger
              htmlType="reset"
              style={{
                marginRight: 10
              }}
            >
              Limpar Filtros
            </Button>
            <Button
              type='primary'
              htmlType="submit"
            >
              Pesquisar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        dataSource={barterQuotes?.content}
        columns={columns}
        pagination={{
          ...pagination,
          size: 'default',
          current: pagination.page! + 1,
          onChange: handlePaginationChange,
        }}
      />
    </div>
  )
} 