import { Form, Select, Spin } from 'antd'
import { Rule } from 'antd/es/form'
import { DefaultOptionType } from 'antd/es/select'
import { useState } from 'react'
import { City, CityService } from 'uai-graos-sdk'



interface CityInputProps {
    onCityChange?: ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined
    formProps?: {
        name: string;
        label: string;
        rules: Rule[]
    }
}

export default function CityInput({ onCityChange, formProps }: CityInputProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [cityData, setCityData] = useState<City.City[]>()


    const getCities = async (text: string) => {
        setLoading(true)
        if (text.length === 0) {
            setLoading(false)
            return setCityData([])
        }
        if (text.length > 2) {
            await CityService.searchCity({ city: text })
                .then(resp => {
                    if (resp.length > 0) {
                        setLoading(false)
                        return setCityData(resp)
                    }
                })
        }
    }

    return (
        <Form.Item
            {...formProps}
        >
            <Select
                showSearch
                loading={loading}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onSearch={getCities}
                onChange={onCityChange}
                placeholder='Pesquisar cidade:'
                optionFilterProp="value"
                allowClear
            >
                {cityData?.map(item =>
                    <Select.Option key={item.id} value={`${item.name} - ${item.state?.cod}`}>
                        {item.name} - {item.state?.cod}
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    )
}