import { Form, Select, Spin } from 'antd'
import { Rule } from 'antd/es/form'
import { DefaultOptionType } from 'antd/es/select'
import React, { useEffect, useState } from 'react'
import { Account, AccountService } from 'uai-graos-sdk'


interface AccountSearchInputProps {
    onAccountChange?: ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined
    formProps?: {
        rules?: Rule[];
        label?: string;
        name?: string;
    }
}

export default function AccountSearchInput({ onAccountChange, formProps }: AccountSearchInputProps) {
    const [accounts, setAccounts] = useState<Account.PageAccount>()
    const [loading, setLoading] = useState<boolean>(false)
    const [pagination, setPagination] = useState<Account.Query>({
        size: 5,
        username: ''
    })

    useEffect(() => {
        fetchAccounts(pagination)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchAccounts = (query: Account.Query) => {
        setLoading(true)
        AccountService.getAllAccounts(query).then(setAccounts)
        setLoading(false)
    }

    const onAccountSearch = (value: string) => {
        setPagination({ ...pagination, username: value })
        fetchAccounts(pagination)
    }

    return (
        <Form.Item
            {...formProps}
        >
            <Select
                onChange={onAccountChange}
                onSearch={onAccountSearch}
                placeholder='Pesquisa por nome:'
                showSearch
                notFoundContent={loading ? <Spin size="small" /> : null}
            >
                {accounts?.content?.map((item: Account.Account) => (
                    <Select.Option
                        key={item.id}
                        value={item.username}
                    >
                        {`${item.username} (${item.email})`}
                    </Select.Option>
                ))}
                {
                    accounts?.totalElements && accounts?.totalElements > 5 ?
                        <Select.Option disabled>
                            Mais {(accounts?.totalElements) - 5} contas cadastradas
                        </Select.Option>
                        : null
                }
            </Select>
        </Form.Item>
    )


} 