import { NumericFormat } from 'react-number-format';
import { Form } from 'antd';
import './CashInput.styles.css'

export default function CashInput({
  value,
  onChange,
  disabled,
  name,
  label,
  required,
}: any) {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required }]}
    >
      <NumericFormat
        value={value}
        onChange={onChange}
        thousandSeparator={true}
        prefix={'R$ '}
        decimalScale={2}
        fixedDecimalScale={true}
        allowNegative={false}
        className='cash-input'
        disabled={disabled}
        style={{width: '100%'}}
        />
    </Form.Item>
  );
}
