/* eslint-disable react-hooks/exhaustive-deps */
import { Table, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import Api from '../services/Api';
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export default function OperationList() {
  const [operations, setOperations] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getOperations(pagination.current, pagination.pageSize);
  }, []);

  async function getOperations(
    page: number,
    pageSize: number,

  ) {
    setLoading(true);
    await Api.getOperations(page, pageSize).then((resp) => {
      const { content, totalElements } = resp.data;
      setOperations(content);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: totalElements,
        showTotal: (total: number, range: any) =>
          `${range[0]}-${range[1]} de ${total} registros`,
        current: page,
      }));
      setLoading(false);
    });
  }

  const handlePaginationChange = (
    page: any,
    pageSize: any
  ) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page - 1,
      pageSize: pageSize,
    }));

    getOperations(page - 1, pageSize);
  };

  const columns = [
    {
      key: '1',
      title: 'ID da operação',
      dataIndex: 'id',
    },
    {
      key: '5',
      title: 'Data de criação',
      dataIndex: 'createdAt',
      render: (createdAt: string) => (
        <p>{moment(createdAt).format('DD/MM/YYYY')}</p>
      ),
    },
    {
      key: '2',
      title: 'Nome do Comprador',
      dataIndex: 'operationBuyer',
      render: (operationBuyer: any) => (
        <p>{operationBuyer.buyerName}</p>
      ),
    },
    {
      key: '3',
      title: 'Nome do Vendedor',
      dataIndex: 'operationSeller',
      render: (operationSeller: any) => (
        <p>{operationSeller.sellerName}</p>
      ),
    },
    {
      key: '6',
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => {
        if (status === 'DELAYED') {
          return <Tag color='magenta'>ATRASADO</Tag>;
        }
        if (status === 'CANCELLED') {
          return <Tag color='red'>CANCELADO</Tag>;
        }
        if (status === 'REFUSED') {
          return <Tag color='volcano'>RECUSADO</Tag>;
        }
        if (status === 'SOLD') {
          return <Tag color='green'>VENDIDO</Tag>;
        }
        if (status === 'PAID') {
          return <Tag color='cyan'>PAGO</Tag>;
        }
        if (status === 'UNDER_ANALYSIS') {
          return <Tag color='purple'>EM ANÁLISE</Tag>;
        }
      },
    },
    {
      key: '7',
      title: 'Valor Bruto',
      dataIndex: 'operationBroker',
      render: (operationBroker: any) => (
        <p>R$ {operationBroker.grossPaymentValue}</p>
      ),
    },
    {
      key: '8',
      title: 'Ações',
      dataIndex: 'id',
      render: (id: any) => (
        <Tooltip title='Visualizar operação'>
          <Link to={`operations/${id}`}>
            <EyeOutlined />
          </Link>
        </Tooltip>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={operations}
      pagination={{
        ...pagination,
        current: pagination.current + 1,
        onChange: handlePaginationChange,
      }}
      loading={loading}
    />
  );
}
