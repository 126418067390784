export default function getSideBarColor(pathname?: string) {
  if (window.location.pathname === pathname) {
    return {
      backgroundColor: "#1976d2",
      color: "#fff",
    };
  }
  return {
    backgroundColor: "#fff",
    color: "#000",
  }
}
