import {
  Col,
  Divider,
  Form,
  Input,
  Select,
  Typography,
} from 'antd';

export default function ConcractSellerForm({
  showForm,
}: any) {
  const { Item } = Form;
  const { Title } = Typography;

  const requiredRule = [
    { required: true, message: 'O campo é obrigatório!' },
  ];

  const funRuralOptions = [
    { value: 'Na Folha', label: 'Na Folha', key: '1' },
    { value: 'Na nota', label: 'Na Nota', key: '2' },
  ];

  const maritalStatusOptions = [
    { label: 'Solteiro(a)', value: 'solteiro', key: '1' },
    { label: 'Casado(a)', value: 'casado', key: '2' },
    { label: 'Viúvo(a)', value: 'viúvo', key: '3' },
    {
      label: 'Divorciado(a)',
      value: 'divorciado',
      key: '4',
    },
  ];

  const grainOptions = [
    { label: 'Milho', value: 'Milho', key: '1' },
    { label: 'Soja', value: 'Soja', key: '2' },
    { label: 'Sorgo', value: 'Sorgo', key: '3' },
  ];

  return (
    <Col span={12}>
      <Title level={4}>Dados do Vendedor</Title>
      <Divider />
      <Item
        rules={requiredRule}
        label='Nome do usuário'
        name='sellerName'
      >
        <Input placeholder='Ex: José de Alcantra' />
      </Item>

      <Item
        rules={requiredRule}
        label='CPF'
        name='sellerDocument'
      >
        <Input
          placeholder={'E.g.: 142.536.542-45'}
        />
      </Item>
      <Item rules={requiredRule} label='RG' name='sellerCi'>
        <Input placeholder='Ex: 99.999.999-0' />
      </Item>
      <div
        style={{
          display: !showForm ? 'none' : 'inherit',
        }}
      >
        <Item
          rules={requiredRule}
          label='Orgão Emissor RG'
          name='sellerCiDispatched'
        >
          <Input placeholder='Ex: Polícia Civil' />
        </Item>
        <Item rules={requiredRule} label='CEP' name='sellerCep'>
          <Input
            placeholder={'E.g.: 38790000'}
          />
        </Item>
        <Item
          rules={requiredRule}
          label='Local de Entrega'
          name='sellerDeliveryPlace'
        >
          <Input placeholder='Informe o nome do local:' />
        </Item>
        <Item
          rules={requiredRule}
          label='Estado'
          name='sellerState'
        >
          <Input placeholder='Ex: MG, GO, SP...' />
        </Item>
        <Item
          rules={requiredRule}
          label='Cidade'
          name='sellerCity'
        >
          <Input placeholder='Ex: Uberlândia, Pará de Minas...' />
        </Item>
        <Item
          rules={requiredRule}
          label='Bairro'
          name='sellerDistrict'
        >
          <Input placeholder='Ex: Leblon, Ipanema, Lago Sul...' />
        </Item>
        <Item
          rules={requiredRule}
          label='Endereço'
          name='sellerStreet'
        >
          <Input placeholder='Ex: Av. Brasil, Rua das Palmeiras' />
        </Item>
        <Item
          rules={requiredRule}
          label='Número do local'
          name='sellerAddressNumber'
        >
          <Input placeholder='Ex: 365' />
        </Item>
        <Item
          rules={requiredRule}
          label='Complemento'
          name='sellerComplement'
        >
          <Input placeholder='Ex: Casa, apto 201' />
        </Item>
        <Item
          rules={requiredRule}
          label='Tipo de Grão'
          name='sellerGrainType'
        >
          <Select
            placeholder='Selecione o tipo de grão:'
            options={grainOptions}
          />
        </Item>
        <Item
          rules={requiredRule}
          label='Estado Civil'
          name='sellerMaritalStatus'
        >
          <Select
            options={maritalStatusOptions}
            placeholder='Selecione o Estado Civil:'
          />
        </Item>
        <Item
          rules={requiredRule}
          label='Quantidade'
          name='sellerQuantity'
        >
          <Input
            placeholder='Informe a quantidade:'
            inputMode='numeric'
          />
        </Item>
        <Item
          rules={requiredRule}
          label='Responsável pela entrega'
          name='sellerResponsibleForDelivery'
        >
          <Input placeholder='Informe o nome do responsável:' />
        </Item>
        <Item
          rules={requiredRule}
          label='Inscrição Estadual'
          name='sellerStateRegistration'
        >
          <Input placeholder={'Ex.: 003.454.434.00-98'} />
        </Item>
        <Item
          rules={requiredRule}
          label='Safra'
          name='sellerVintage'
        >
          <Input placeholder='Informe o ano da safra: Ex: 2022/2023' />
        </Item>
        <Item
          rules={requiredRule}
          label='Nota de Funrural'
          name='sellerRuralBackground'
        >
          <Select
            options={funRuralOptions}
            placeholder='Selecione a opção do Funrural:'
          />
        </Item>
        <Item
          rules={requiredRule}
          label='Prazo de entrega'
          name='sellerDeadlineDelivery'
        >
          <Input placeholder='Informe o prazo de entrega:' />
        </Item>
      </div>
    </Col>
  );
}
