/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, notification } from "antd"
import { Company, CompanyService } from "uai-graos-sdk"
import AuthService from "../../auth/Authorization.service"
import { useEffect, useState } from "react"
import { ExclamationCircleFilled } from '@ant-design/icons'

export const useCompany = () => {
    const { confirm } = Modal
    const APP_BASE_URL = process.env.REACT_APP_BACK_OFFICE_BASE_URL;

    const [accounts, setAccounts] = useState<Company.Company[]>()
    const [pagination, setPagination] = useState<Company.Query>({
        page: 0,
        size: 10
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        getPreRegisters(pagination)
    }, [])

    const createPreRegister = async (body: Company.Input) => {
        const accountId = AuthService.getAccount()
        setIsLoading(true)
        await CompanyService.createPreRegister(accountId!, body)
            .then(() => {
                notification.success(
                    {
                        message: 'Sucesso!',
                        description: 'O pré-cadastro foi concluido!'
                    }
                )
            })
        setIsLoading(false)
    }

    const getPreRegisters = async (query: Company.Query) => {
        setIsLoading(true)
        await CompanyService.getAllPreRegisters(pagination)
            .then((resp: Company.Page) => {
                const { content, totalElements } = resp
                setAccounts(content)
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    total: totalElements,
                    showTotal: (total: number, range: any) =>
                        `${range[0]}-${range[1]} de ${total} registros`,
                    page: query.page,
                }));
            })
        setIsLoading(false)
        }

         const handlePaginationChange = (
            page: number,
            pageSize: number,
        
        ) => {          
            getPreRegisters(
                 {
                     ...pagination,
                     page: page - 1,
                     size: pageSize
                 }
             )
        
        }
        

        const createAccountByCompany = async (companyId:string, record: Company.Company) => {
            confirm({
                title: 'Atenção!',
                icon: <ExclamationCircleFilled />,
                content: `Você deseja mesmo transformar o pré-cadastro de ${record.name} em um usuário?`,
                okText: 'Sim',
                cancelText: 'Não',
                onOk: async () =>  {
                   await CompanyService.createAccountByCompany(companyId)
                   .then(() => {
                    notification.success(
                        {
                            message: 'Sucesso!',
                            description: 'O cadastro foi concluído!'
                        }
                    )
                    window.location.href = `${APP_BASE_URL}/users`
                   })
                },
                onCancel() { },
            });

        }


        
    return { createPreRegister, accounts, getPreRegisters, pagination, handlePaginationChange, isLoading, createAccountByCompany }

}