import { notification, Skeleton } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useBid from '../../core/hooks/useBid';
import { Bid, BidService } from 'uai-graos-sdk';
import BidForm from '../features/BidCreateForm';

export default function BidEditView() {
  const params = useParams<{ id: string }>();
  const { bid, fetchBid } = useBid();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    fetchBid(params.id);
  }, [fetchBid, params.id]);

  const transformBidData = useCallback(
    (bid: Bid.Bid) => {
      return {
        ...bid,
        deliveryMonth: moment(bid.createdAt),
        city: bid.address?.city,
        cep: bid.address?.cep,
        state: bid.address?.state
      };
    },
    []
  );
  
function convertPrice(price:number | undefined){
  if(!price){
    return price;
  }
  const priceString = String(price);
  const transform = priceString.replace('R$', '')
  const priceNumber = Number(transform)

  return priceNumber;
}

  const transformPrice = useCallback(
    (bid: Bid.BidUpdateInput) => {      
      return {
        ...bid,
        price: convertPrice(bid.price),
        bestPrice: convertPrice(bid.bestPrice),
        city: bid.city?.split(" -")[0]
      };
    },
    []
  );



  async function handleBidUpdate(bids: Bid.Bid) {
    setIsLoading(true)
    await BidService.duplicateBid(params.id, transformPrice(bids))
    .then(() => {
      notification.success({
        message: 'A oferta foi atualizada!',
      });
      history.push('/bids');
      setIsLoading(false)
    });
    setIsLoading(false)
  }

  if (!bid) return <Skeleton />;

  return (
    <>
      <BidForm
        OnUpdate={handleBidUpdate}
        bid={transformBidData(bid)}
        isLoading={isLoading}
      />
    </>
  );
}