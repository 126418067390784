import { Table, Tag, notification } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { MarketData, MarketDataService } from 'uai-graos-sdk';

export default function ListMarketData() {
  const [data, setData] = useState<MarketData.MarketData[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTableData();
  }, []);

  async function getTableData() {
    return await MarketDataService.getAllCotations({})
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          notification.error({
            style: {zIndex: 100},
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
          });
        } else if (err.request) {
          notification.error({
            style: {zIndex: 100},
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            style: {zIndex: 100},
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
        notification.error({
          style: {zIndex: 100},
          message: 'Erro!',
          description: `Por favor entre em contato com seu administrador! Erro código: ${err.config}`,
        });
      });
  }

  const columns = [
    {
      title: 'Data',
      dataIndex: 'marketDataId',
      key: 'date',
      render: (item: any) => {
        return (
          <div>
            {moment(item.date).format('DD/MM/YYYY')}
          </div>
        );
      },
    },
    {
      title: 'Produto',
      dataIndex: 'marketDataId',
      key: 'symbol',
      render: (item: any) => {
        return <div>{item.symbol}</div>;
      },
    },
    {
      title: 'Preço',
      dataIndex: 'lastPrice',
      key: 'lastPrice',
      render: (item: any) => {
        return (
          <div>
            <Tag color='green'>
              R$
              {item.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Tag>
          </div>
        );
      },
    },
    {
      title: 'Variação',
      dataIndex: 'variation',
      key: 'variation',
      render: (item: any) => {
        return (
          <Tag
            color={
              item < 0
                ? 'red'
                : item === 0
                ? 'blue'
                : 'green'
            }
          >
            {item}
          </Tag>
        );
      },
    },
  ];

  return (
    <Table
      loading={loading}
      dataSource={data}
      columns={columns}
    />
  );
}
