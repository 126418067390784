import {
  Col,
  Divider,
  Form,
  Input,
  Typography,
} from 'antd';

export default function ConcractBuyerForm({
  showForm,
}: any) {
  const { Item } = Form;
  const { Title } = Typography;

  const requiredRule = [
    { required: true, message: 'O campo é obrigatório!' },
  ];

  return (
    <Col span={12}>
      <Title level={4}>Dados do Comprador</Title>
      <Divider />
      <Item
        rules={requiredRule}
        label='Nome do usuário'
        name='buyerName'
      >
        <Input
          placeholder='Ex: José de Alcantra'
          disabled
        />
      </Item>

      <Item
        rules={requiredRule}
        label='CPF'
        name='buyerDocument'
      >
        <Input
          placeholder={'E.g.: 142.536.542-45'}
          disabled
        />
      </Item>
      <Item rules={requiredRule} label='RG' name='buyerCi'>
        <Input placeholder='Ex: 99.999.999-0' disabled />
      </Item>
      <div
        style={{
          display: !showForm ? 'none' : 'inherit',
        }}
      >
        <Item
          rules={requiredRule}
          label='Orgão Emissor RG'
          name='buyerCiDispatched'
        >
          <Input placeholder='Ex: Polícia Civil' disabled />
        </Item>
        <Item
          rules={requiredRule}
          label='CEP'
          name='buyerCep'
        >
          <Input
            placeholder={'E.g.: 38790000'}
            disabled
          />
        </Item>
        <Item
          rules={requiredRule}
          label='Estado'
          name='buyerState'
        >
          <Input placeholder='Ex: MG, GO, SP...' disabled />
        </Item>
        <Item
          rules={requiredRule}
          label='Cidade'
          name='buyerCity'
        >
          <Input
            placeholder='Ex: Uberlândia, Pará de Minas...'
            disabled
          />
        </Item>
        <Item
          rules={requiredRule}
          label='Bairro'
          name='buyerDistrict'
        >
          <Input
            placeholder='Ex: Leblon, Ipanema, Lago Sul...'
            disabled
          />
        </Item>
        <Item
          rules={requiredRule}
          label='Endereço'
          name='buyerStreet'
        >
          <Input
            placeholder='Ex: Av. Brasil, Rua das Palmeiras'
            disabled
          />
        </Item>
        <Item
          rules={requiredRule}
          label='Número do local'
          name='buyerAddressNumber'
        >
          <Input placeholder='Ex: 365' disabled />
        </Item>
      </div>
    </Col>
  );
}
