import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Tabs,
} from 'antd';
import Api from '../services/Api';
import { MaskedInput } from 'antd-mask-input';
import { useEffect, useState } from 'react';
import moment from 'moment';

const { TabPane } = Tabs;

const showModalities = [
  {
    id: '1',
    type: 'Disponível',
  },
  {
    id: '2',
    type: 'Futuro',
  },
];

export default function PaymentSell() {
  const [users, setAccounts] = useState([]);
  const [userName, setUserName] = useState('');
  const [products, setProducts] = useState([]);
  const [acceptCounterOffer, setAcceptCounterOffer] = useState(false);
  const [modality, setModality] = useState('');
  const [deliveryDate, setDeliveryDate] = useState(null);
  const side = 'V';

  const registerOfferSell = async (values: any) => {
    while (userName === "") {
      await Api.getAccountById(values.accountId).then((e) => {
        setUserName(e.data.username)
      }).catch((e) => console.log(e));
    }

    function filterBestPrice(bestPrice: string) {
      if (!bestPrice) return
      const replaceCounterOffer = bestPrice.replace(',', '.');
      return parseFloat(replaceCounterOffer);
    }

    const req = await Api.sendNewOrderSell(
      values.accountId,
      userName,
      values.product,
      side,
      values.quantity,
      values.price,
      values.paymentForm,
      values.obs,
      values.deliveryForm,
      values.minimumQuantity,
      '',
      values.modality,
      values.storage,
      deliveryDate,
      values.cep,
      acceptCounterOffer,
      filterBestPrice(values.counterOfferValue),
    ).then(async (response: any) => {
      const req = await response
      notification.success({
        message: 'Sucesso',
        description: 'Oferta criada com sucesso',
      });
      return req.data
    }).catch((error) => {
      notification.error({
        message: 'Erro!',
        description:
          `Erro código ${error.response.status}, por favor entre em contato com o Administrador`,
      });
    })
    return req;
  };

  useEffect(() => {
    Api.getAccounts(0).then((value) => {
      setAccounts(value.data.content)
    });
    Api.getOffersNumberBySide('V').then(setProducts);
  }, []);

  const showDatePicker = () => {
    if (modality === 'Futuro') {
      return true;
    } else {
      return false;
    }
  }
  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    const formattedDate: any = moment(dateString).format('DD/MM/YYYY')
    if (formattedDate === 'Invalid Date') {
      setDeliveryDate(null);
    } else {
      setDeliveryDate(formattedDate)
    }
  };
  const payments = [
    {
      id: '1',
      type: 'No carregamento',
    },
    {
      id: '2',
      type: 'No recebimento',
    },

    {
      id: '3',
      type: 'A prazo máximo de 30 dias',
    },
    {
      id: '4',
      type: 'À combinar',
    },
  ];
  const freights = [
    {
      id: '1',
      type: 'CIF - Por conta do Vendedor',
    },
    {
      id: '2',
      type: 'FOB - Por conta do Comprador',
    },
    {
      id: '3',
      type: 'À combinar - CIF ou FOB',
    },
  ];
  return (
    <Form
      autoComplete={'off'}
      layout={'vertical'}
      onFinish={registerOfferSell}
    >
      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Form.Item label={'Usuário'} name={['accountId']} rules={[
            {
              required: true,
              message: 'Campo Obrigatório',
            },
          ]}>
            <Select placeholder={'Selecione um usuário'} showSearch>
              {users.map((user: any) => {
                return (
                  <Select.Option
                    key={user.id}
                    value={user.id}
                  >
                    {user.username}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item label={'Produto'} name={['product']} rules={[
            {
              required: true,
              message: 'Campo Obrigatório',
            },
          ]}>
            <Select showSearch placeholder='Selecione o produto'>
              {products.map((product: any) => (
                <Select.Option
                  key={product.id}
                  value={product.product}
                >
                  {product.product}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Campo Obrigatório',
              },
            ]}
            label={'Modalidade'}
            name={['modality']}
          >
            <Select showSearch value={modality} onChange={(e) => setModality(e)} placeholder='Selecione uma modalidade'>
              {showModalities.map((modality) => (
                <Select.Option
                  key={modality.id}
                  value={modality.type}
                >
                  {modality.type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={'CEP'}
            name={['cep']}
            rules={[
              {
                required: true,
                message: 'Campo Obrigatório',
              },
            ]}
          >
            <MaskedInput
              mask='11111-111'
              placeholder={'Ex.: 37800-500'}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={'Preço'}
            name={'price'}
            rules={[
              {
                required: true,
                message: 'Campo Obrigatório',
              },
            ]}
          >
            <Input prefix="R$" placeholder={'Ex.: R$100,00'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={'Quantidade de Sacas'}
            name={'quantity'}
            rules={[
              {
                required: true,
                message: 'Campo Obrigatório',
              },
            ]}
          >
            <Input placeholder={'Quantidade de Sacas'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Armazenamento'}
            name={'storage'}
            rules={[
              {
                required: true,
                message: 'Campo Obrigatório',
              },
            ]}
          >
            <Input placeholder={'Armazenamento'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Observação Adicional'}
            name={'obs'}
            rules={[
              {
                required: true,
                message: 'Campo Obrigatório',
              },
            ]}
          >
            <Input placeholder={'Observação Adicional'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label="Forma de pagamento"
            name="paymentForm"
            rules={[{ required: true, message: 'Este campo não pode estar em branco!' }]}
          >
            <Select showSearch placeholder='Selecione uma forma de pagamento'>
              {payments.map((payments: any) => (
                <Select.Option
                  key={payments.id}
                  value={payments.type}
                >
                  {payments.type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label="Quantidade Mínima de saca por Negociação "
            name="minimumQuantity"
            rules={[{ required: true, message: 'Este campo não pode estar em branco!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label="Total de Sacas"
            name="totalBags"
            rules={[{ required: true, message: 'Este campo não pode estar em branco!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label="Forma de Entrega"
            name="deliveryForm"
            rules={[{ required: true, message: 'Este campo não pode estar em branco!' }]}
          >
            <Select showSearch>
              {freights.map((freights: any) => (
                <Select.Option
                  key={freights.id}
                  value={freights.type}
                >
                  {freights.type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label="Aceita Contraproposta?"
            rules={[{ required: false }]}
          >
            <Checkbox
              checked={acceptCounterOffer}
              onChange={(e) => setAcceptCounterOffer(e.target.checked)}
            >{acceptCounterOffer ? 'Sim' : 'Não'}</Checkbox>
          </Form.Item>
        </Col>


      </Row>
      <Tabs
        defaultActiveKey={'personal'}
      >
        <TabPane
          key={'personal'}
          tab={'Campos Opcionais'}
        >
          <Row gutter={24}>
            <Col xs={24} lg={8}>

              <Form.Item
                label="Valor da contraproposta"
                name="counterOfferValue"
                rules={[{ required: acceptCounterOffer, message: "Por favor insira o valor da contraproposta" }]}
              >
                <Input
                  disabled={!acceptCounterOffer}
                  placeholder='Ex.: 127,87'
                  prefix="R$"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label="Data de entrega"
                name="deliveryDate"
                rules={[{ required: showDatePicker(), message: 'Por favor selecione a data de entrega!' }]}
              >
                <DatePicker disabled={!showDatePicker()} onChange={onChangeDate} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      <Divider />
      <Row gutter={24} justify={'end'}>
        <Col>
          <Button type={'primary'} htmlType={'submit'}>
            Cadastrar Dados
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
