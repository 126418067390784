/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form,
  notification,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NegotiationModal from '../components/NegotiationModal/NegotiationModal';
import Api from '../services/Api';
import { Account, AccountService, Negotiation, NegotiationService } from 'uai-graos-sdk';


export default function NegotiationsList() {
  const [accountsEmails, setAccountsEmails] = useState<Account.AccountEmails>();
  const [loading, setLoading] = useState<boolean>(false);
  const [negotiations, setNegotiations] = useState<Negotiation.Negotiation[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>(null);
  const [pagination, setPagination] = useState<Negotiation.Query>({
    page: 0,
    size: 10,
  })

  const [
    negotiationStatus,
    setNegotiationStatus,
  ] = useState('');

  const updateStatus = async () => {
    await Api.updateNegotiation(
      modalData?.id,
      negotiationStatus
    )
      .then(async (resp) => {
        const response = await resp.data;
        notification.success({
          message: 'Sucesso',
          description: 'Negociação atualizada com sucesso!',
        });
        // await NegotiationsResponseApi(selectedAccount, '');
        return response.data.content;
      })
      .catch((error) => {
        notification.error({
          message: 'Erro!',
          description: `Erro código ${error.response.status}, por favor entre em contato com o Administrador`,
        });
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    await updateStatus();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const changeSelectedStatus = async (e: string) => {
    setNegotiationStatus(e);
  };

  const columns = [
    {
      title: 'Preço',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) =>
        <Tag color={'green'}>
          {price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </Tag>
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Origem',
      dataIndex: 'origin',
      key: 'origin',
      render: (origin: String) => {
        return origin === 'COUNTER_BID' ? (
          <p>Contraproposta</p>
        ) : (
          <p>Contato Direto</p>
        ) 
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: String) => {
        return status === 'AWAITING' ? (
          <Tag color={'blue'}>EM ESPERA</Tag>
        ) : status === 'FINISHED' ? (
          <Tag color={'green'}>FINALIZADO</Tag>
        ) : (
          <Tag color={'pink'}>CANCELADO</Tag>
        );
      },
    },
    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      render: (date: number) => {
        return (
          <p>{moment(date).utc().format('DD/MM/YYYY')}</p>
        );
      },
      key: 'createdAt',
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size='middle'>
          <Button
            type='primary'
            onClick={() => [
              setModalData(record),
              showModal()
            ]}
          >
            Detalhes
          </Button>
          {record.status === 'FINISHED' ? (
            <a href={record.urlContract}>
              <Button type='link'>Mostrar contrato</Button>
            </a>
          ) : null}
        </Space>
      ),
    },
  ];

  const statusData = [
    {
      key: '1',
      value: 'AWAITING',
      name: 'Em espera',
    },
    {
      key: '2',
      value: 'FINISHED',
      name: 'Concluído',
    },
    {
      key: '3',
      value: 'CANCELLED',
      name: 'Cancelado',
    },
  ];



  useEffect(() => {
    AccountService.getAllAccountEmails().then(setAccountsEmails)
    negotiationsResponseApi(pagination);
  }, []);


  const onFinishFilter = async (values: any) => {

    const account: Account.Account = await AccountService.getAccountByEmail(values.user)


    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 0,
      status: values.side,
      accountId: account?.id
    }));
    negotiationsResponseApi(
      {
        ...pagination,
        page: 0,
        status: values.status,
        accountId: account?.id
      }
    )

  }


  async function negotiationsResponseApi(
    query: Negotiation.Query
  ) {
    setLoading(true)
    await NegotiationService.getAllNegotiations(query).then((res) => {
      const { content, totalElements } = res;
      setNegotiations(content);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: totalElements,
        showTotal: (total: number, range: any) =>
        `${range[0]}-${range[1]} de ${total} registros`,
        page: query.page,
      }));
    })
    setLoading(false)
  }

  const handlePaginationChange = (
    page: any,
    pageSize: any
  ) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: page - 1,
      size: pageSize,
    }));
    negotiationsResponseApi(
      {
        ...pagination,
        page: page - 1,
        size: pageSize
      }
    )
  };

  return (
    <div>
      <NegotiationModal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title='Detalhes da Negociação'
        negotiationId={modalData?.id}
        negotiationPrice={modalData?.price}
        negotiationProduct={modalData?.buyerBid.product}
        bidId={modalData?.buyerBid.id}
        bidUser={modalData?.buyerBid.account.username}
        bidSide={modalData?.buyerBid.side}
        bidCreatedAt={modalData?.buyerBid.createdAt}
        bidModality={modalData?.buyerBid.modality}
        bidPhoneNumber={modalData?.buyerBid.account.phoneNumber}
        bidPrice={modalData?.buyerBid.price}
        bidNegotiateId={modalData?.sellerBid.id}
        bidNegotiateUser={
          modalData?.sellerBid.account.username
        }
        bidNegotiateSide={modalData?.sellerBid.side}
        bidNegotiateCreatedAt={
          modalData?.sellerBid.createdAt
        }
        bidNegotiateModality={
          modalData?.sellerBid.modality
        }
        bidNegotiatePhoneNumber={
          modalData?.sellerBid.account.phoneNumber
        }
        bidNegotiatePrice={modalData?.sellerBid.price}
        changeStatus={changeSelectedStatus}
        defaultStatus={modalData?.id}
        status={negotiationStatus}
      />
      <Form
        autoComplete={'off'}
        layout={'vertical'}
        onFinish={onFinishFilter}
      >
        <Row gutter={24}>
          <Col xs={24} lg={8}>
            <Form.Item
              label='Usuário'
              name='user'
              rules={[
                {
                  required: true,
                  message:
                    'Este campo não pode estar em branco!',
                },
              ]}
            >
              <Select showSearch>
                {accountsEmails?.accountsEmails?.map((a: any) => (
                  <Select.Option key={a} value={a}>
                    {a}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label='Status da negociação'
              name='status'
            >
              <Select>
                {statusData.map((status: any) => (
                  <Select.Option
                    key={status.id}
                    value={status.value}
                  >
                    {status.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Col xs={24} lg={8}>
          <Button type='primary' htmlType={'submit'}>
            Pesquisar
          </Button>
        </Col>
      </Form>
      <Table
        columns={columns}
        dataSource={negotiations}
        pagination={{
          ...pagination,
          size: 'default',
          current: pagination.page! + 1,
          onChange: handlePaginationChange
        }}
        loading={loading}
      />
    </div>
  );
}
