/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Col, Form, Row, Select, Button, Descriptions, Spin, notification, Skeleton, Input } from "antd";
import { Account, AccountService, MerchantResponsibleService, Region, RegionService } from "uai-graos-sdk";
import * as phone from 'telefone'

type FieldType = {
    user?: string;
    regionsIds?: string[];
    type: string;
    email?: string;
    percentageTax?: number;
};


export default function RepresentativeForm() {

    const [accountsEmails, setAccountsEmails] = useState<Account.PageAccount>()
    const [regions, setRegions] = useState<Region.Region[]>()
    const [loading, setLoading] = useState(false)
    const [account, setAccount] = useState<Account.Account | null>()
    const [pagination, setPagination] = useState<Account.Query>({
        size: 10,
        username: ''
    })

    useEffect(() => {
        fetchAccounts(pagination)
        fetchRegions()
    }, [])

    const [form] = Form.useForm();


    const fetchRegions = () => {
        RegionService.getRegions().then(setRegions)
    }
    const fetchAccounts = (query: Account.Query) => {
        setLoading(true)
        AccountService.getAllAccounts(query).then(setAccountsEmails)
        setLoading(false)
    }

    const onAccountSearch = (value: string) => {
        setPagination({ ...pagination, username: value })
        fetchAccounts(pagination)
    }

    const types = [
        { label: "Representante", value: "REPRESENTATIVE" },
        { label: "Gerente", value: "MANAGER" },
        { label: "Diretor", value: "DIRECTOR" },
        { label: "Supervisor", value: "SUPERVISOR" }
    ]

    const filterTypes = (type: string) => {
        switch (type) {
            case 'REPRESENTATIVE':
                return 'Representante'
            case 'MANAGER':
                return 'Gerente'
            case 'DIRECTOR':
                return 'Diretor'
            default:
                return 'Supervisor'
        }
    }

    const validateNumber = (_: any, value: string) => {
        if (value && !/^[0-9]+$/.test(value)) {
            return Promise.reject('Por favor, insira apenas números.');
        }
        return Promise.resolve();
    };

    const onAccountChange = (value: string, option: any) => {
        AccountService.getAccountById(option.key).then(setAccount)
    }

    const onFinish = async (values: any) => {
        await MerchantResponsibleService.createMerchant(
            account?.email!,
           values
        ).then(() => {
            notification.success({
                message: 'Sucesso!',
                description: `A conta de ${account?.username} foi promovida para ${filterTypes(values.type)}`
            })
            form.resetFields()
            setAccount(null)
        })

    }

    if(loading) return <Skeleton />

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={24}>
                <Col lg={8} sm={24}>
                    <Form.Item<FieldType>
                        label='Usuário:'
                        name='user'
                        rules={[
                            { required: true, message: 'Por favor, insira o usuário.' },
                        ]}
                    >
                        <Select
                            onChange={onAccountChange}
                            placeholder='Pesquisa por nome:'
                            showSearch
                            onSearch={onAccountSearch}
                            notFoundContent={loading ? <Spin size="small" /> : null}
                        >
                            {accountsEmails?.content?.map((a: Account.Account) => (
                                <Select.Option
                                    key={a.id}
                                    value={`${a.username} (${a.email})`}
                                >
                                    {`${a.username} (${a.email})`}
                                </Select.Option>
                            ))}
                        </Select>

                    </Form.Item>
                </Col>
                <Col lg={8} sm={24}>
                    <Form.Item<FieldType>
                        label='E-Mail:'
                        name='email'
                        rules={[
                            { required: true, message: 'Por favor, insira o e-mail.' },
                        ]}
                    >
                        <Input
                            placeholder="E-Mail:"
                        />
                    </Form.Item>
                </Col>
                <Col lg={8} sm={24}>
                    <Form.Item<FieldType>
                        label='Regiões de atuação:'
                        name='regionsIds'
                        // rules={[
                        //     { required: true, message: 'Por favor, insira as regiões do representante.' },
                        // ]}
                    >
                        <Select
                            placeholder='Região:'
                            showSearch
                            mode="multiple"
                            optionFilterProp="key"
                        >
                            {regions?.map((a) => (
                                <Select.Option key={a.name} value={a.id}>
                                    {a.name} - {a.uf}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={8} sm={24}>
                    <Form.Item<FieldType>
                        label='Porcentagem de Comissão:'
                        name='percentageTax'
                        rules={[
                            { required: true, message: 'Por favor, insira a porcentagem de comissão.' },
                            { validator: validateNumber },
                        ]}
                    >
                        <Input
                            placeholder="Porcentagem de Comissão:"
                            suffix='%'
                        />
                    </Form.Item>
                </Col>
                <Col lg={8} sm={24}>
                    <Form.Item<FieldType>
                        label='Tipo de cargo:'
                        name='type'
                        initialValue={'REPRESENTATIVE'}
                    >
                        <Select
                            options={types}
                            placeholder="Tipo de cargo:"
                            defaultValue={'REPRESENTATIVE'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'end'}>
                <Col>
                    <Button type='primary' htmlType='submit'>
                        Cadastrar
                    </Button>
                </Col>
            </Row>
            {
                account &&
                <Row gutter={24}>
                    <Col lg={8} sm={24}>
                        <h3>Informações do Usuário:</h3>
                        <Descriptions column={1} bordered size='small'>
                            <Descriptions.Item label={'Nome:'}>
                                {account?.username}
                            </Descriptions.Item>
                            <Descriptions.Item label={'Cidade:'}>
                                {account?.city}
                            </Descriptions.Item>
                            <Descriptions.Item label={'Estado:'}>
                                {account?.state}
                            </Descriptions.Item>
                            <Descriptions.Item label={'CPF/CNPJ:'}>
                                {account.cpfCnpj
                                    ? account?.cpfCnpj
                                    : 'Documento não cadastrado'}
                            </Descriptions.Item>
                            <Descriptions.Item label={'Telefone:'}>
                                {!phone.format(account?.phoneNumber)
                                    ? 'Telefone não cadastrado'
                                    : `${phone.format(account?.phoneNumber)}`
                                }
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            }
        </Form>
    )
}