import { Button } from 'antd';
import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const LoginArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
`;

export const LogoContainer = styled.img`
  height: 50%;
  width: 50%;
`;

export const FormContainer = styled.div`
  border-left: 1px solid #ccc;
  padding-left: 20px;
`;

export const ForgotPasswordButton = styled(Button)`
  margin-top: 10px;
`;
