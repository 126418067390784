/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, Col, Descriptions, Divider, Row, Skeleton, Table, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Barter, BarterProductService } from 'uai-graos-sdk';
import Profile from './../../assets/Profile.png';
import { ArrowRightOutlined } from '@ant-design/icons';



export default function BarterQuotationDetails() {

    const params = useParams<{ id: string }>();

    const [barter, setBarter] = useState<Barter.BarterQuotationDTO>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        getQuotaion(params.id)
    }, [])

    async function getQuotaion(id: string) {
        setLoading(true)
        await BarterProductService.getBarterQuotationById(id)
            .then(setBarter)
        setLoading(false)
    }

    const columns = [
        {
            title: 'ID do Produto',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Produto',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Quantidade',
            dataIndex: 'quantity',
            key: 'quantity',
        },
    ];

    const convertDate = (value: string) => {
        switch (value) {
            case 'DAYS_30':
                return '30 dias'
            case 'DAYS_60':
                return '60 dias'
            case 'DAYS_90':
                return '90 dias'
            case 'DAYS_120':
                return '120 dias'
            case 'DAYS_150':
                return '150 dias'
            default:
                return null;
        }
    }
    const convertPayment = (value: string) => {
        switch (value) {
            case 'CASH_RECEIPT':
                return 'Boleto á vista'
            case 'TERM_BILL':
                return 'Boleto á prazo'
            default:
                return null;
        }
    }
    return (
        <div>
            {
                loading ? (
                    <Skeleton />
                )
                    :
                    <>
                        <Row align='middle'>
                            <Col style={{ padding: '0px 24px' }}>
                                <Avatar size={120} src={Profile} />
                            </Col>
                            <Col style={{ padding: '0px 24px' }}>
                                <Typography.Title level={2}>
                                    {barter?.username}
                                </Typography.Title>
                                <Typography.Paragraph>
                                    Status da cotação: <Tag color={barter?.status === 'ACTIVE' ? 'green' : 'red'}>
                                        {barter?.status === 'ACTIVE' ? 'ATIVO' : 'CANCELADO'}
                                    </Tag>
                                </Typography.Paragraph>
                                <Link to={`/users/${barter?.accountId}`}>
                                <Button>
                                    Detalhes do usuário <ArrowRightOutlined />
                                </Button>
                                </Link>
                            </Col>
                        </Row>
                        <Divider/>
                        <Row style={{ marginTop: 20, marginBottom: 20 }} gutter={48}>
                            <Col xs={24} lg={12}>
                            <Typography.Title level={3}>
                                   Detalhes da cotação:
                                </Typography.Title>
                                <Descriptions column={1} bordered size='small'>
                                    <Descriptions.Item label={'Grão'}>
                                        <Tag color='blue'>
                                            {barter?.production}
                                        </Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'Área:'}>
                                        {barter?.area} Hectáres
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'Pagar em:'}>
                                        {convertDate(barter?.payIn!)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'Receber em:'}>
                                        {convertDate(barter?.receiveIn!)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'Pagamento:'}>
                                        {convertPayment(barter?.payment!)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'Endereço:'}>
                                        {barter?.address?.address}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'Cidade:'}>
                                        {barter?.address?.city}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'Estado:'}>
                                        {barter?.address?.state}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'CPF/CNPJ:'}>
                                        {barter?.document}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                            <Col xs={24} lg={12}>
                            <Typography.Title level={3}>
                                   Produtos dentro da cotação:
                                </Typography.Title>
                                <Table
                                    columns={columns}
                                    dataSource={barter?.items} />
                            </Col>
                        </Row>
                    </>
            }

        </div >
    )
}