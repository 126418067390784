/* eslint-disable react-hooks/exhaustive-deps */
import { Table, Tooltip, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import Api from '../services/Api';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

export default function ConcractUploadForm() {
  const [contracts, setContracts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  })

  useEffect(() => {
    getContracts(pagination.current, pagination.pageSize);
  }, []);

  const getContracts = async (page: number, size : number) => {
    setLoading(true);
    await Api.getAllContracts(page, size)
      .then((resp) => {
        const { content, totalElements } = resp.data;
        setContracts(content);
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalElements,
          showTotal: (total: number, range: any) =>
            `${range[0]}-${range[1]} de ${total} registros`,
          current: page,
        }));
      })
      .catch((err) => {
        if (err.response) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.response.status}`,
          });
        } else if (err.request) {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Erro código: ${err.request}`,
          });
        } else {
          notification.error({
            message: 'Erro!',
            description: `Por favor entre em contato com seu administrador! Mesagem do erro: ${err.message}`,
          });
        }
        notification.error({
          message: 'Erro!',
          description: `Por favor entre em contato com seu administrador! Erro código: ${err.config}`,
        });
      });
    setLoading(false);
  };

  const handlePaginationChange = (
    page: any,
    pageSize: any
  ) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page - 1,
      pageSize: pageSize,
    }));

    getContracts(page - 1, pageSize);
  };


  const columns = [
    {
      title: 'ID do contrato',
      dataIndex: 'id',
      key: '1',
    },
    {
      title: 'Nome do comprador',
      dataIndex: 'buyerName',
      key: '2',
    },
    {
      title: 'Nome do vendedor',
      dataIndex: 'sellerName',
      key: '3',
    },
    {
      title: 'Tipo de grão',
      dataIndex: 'grainType',
      key: '4',
    },
    {
      title: 'Preço',
      dataIndex: 'price',
      key: '5',
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantityBags',
      key: '6',
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: '6',
      render: (id: string) => {
        return (
          <Link to={`/contract/sign/${id}`}>
            <Tooltip title='Assinar contrato'>
              <EditOutlined />
            </Tooltip>
          </Link>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={contracts}
      pagination={{
        ...pagination,
        current: pagination.current + 1,
        onChange: handlePaginationChange,
      }}
      loading={loading}
    />
  );
}
