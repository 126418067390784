import React from 'react';
import {
  AppBar,
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  LocalOfferOutlined,
  HomeOutlined,
  BorderAllOutlined,
  MenuOutlined,
  PersonOutline,
  ExpandLess,
  ControlPoint,
  PeopleOutline,
  TrendingUp,
  SwapHoriz,
  Receipt,
  NoteAddOutlined,
  FilePresentOutlined,
  NoteOutlined,
  HandshakeOutlined,
  AttachMoneyOutlined,
  ExpandMore,
  LogoutOutlined,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import getSideBarColor from '../../../core/utils/getSideBarColor';

const drawerWidth = 240;

interface Props {
  window?: () => {
    document: {
      body: HTMLElement | null;
    };
  };
  readonly children: React.ReactNode;
}

const APP_BASE_URL =
  process.env.REACT_APP_BACK_OFFICE_BASE_URL;

interface CollapseState {
  users: boolean;
  representative: boolean;
  bids: boolean;
  marketData: boolean;
  barter: boolean;
  negotiations: boolean;
  contract: boolean;
  counterBid: boolean;
  operation: boolean;
}

export default function MuiLayout(props: Props) {
  const { confirm } = Modal;
  const layout = props.window;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [collapse, setCollapse] =
    React.useState<CollapseState>({
      users: false,
      representative: false,
      bids: false,
      marketData: false,
      barter: false,
      negotiations: false,
      contract: false,
      counterBid: false,
      operation: false,
    });

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const navigation = useHistory();

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handlePageToggle = (url: string) => {
    navigation.push(url);
    handleDrawerToggle();
  };

  const handleLogout = async () => {
    confirm({
      title: 'Atenção!',
      icon: <ExclamationCircleFilled />,
      content: 'Você deseja mesmo sair do Back-Office?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk() {
        return new Promise(() => {
          window.localStorage.clear();
          window.location.href = `${APP_BASE_URL}/login`;
        });
      },
      onCancel() {},
    });
  };

  const handleCollapseChange = (
    key: keyof CollapseState
  ) => {
    if (collapse[key]) {
      setCollapse({ ...collapse, [key]: false });
    } else {
      const updatedCollapse: CollapseState =
        {} as CollapseState;
      Object.keys(collapse).forEach((stateKey) => {
        updatedCollapse[stateKey as keyof CollapseState] =
          stateKey === key;
      });
      setCollapse(updatedCollapse);
    }
  };

  const items = [
    {
      title: 'Home',
      url: '/',
      icon: HomeOutlined,
      onClick: () => {
        handlePageToggle('/');
      },
    },
    {
      title: 'Usuários',
      url: 'users-menu',
      icon: PersonOutline,
      collapse: collapse.users,
      onClick: () => {
        handleCollapseChange('users');
      },
      subItems: [
        {
          title: 'Listar usuários',
          url: '/users',
          icon: BorderAllOutlined,
          onClick: () => handlePageToggle('/users'),
          colors: getSideBarColor('/users'),
        },
        {
          title: 'Listar pré-cadastros',
          url: '/company',
          icon: BorderAllOutlined,
          onClick: () => handlePageToggle('/company'),
          colors: getSideBarColor('/company'),
        },
        {
          title: 'Cadastrar usuário',
          url: '/users/register',
          icon: ControlPoint,
          onClick: () =>
            handlePageToggle('/users/register'),
          colors: getSideBarColor('/users/register'),
        },
      ],
    },
    {
      title: 'Representantes',
      url: 'representative-menu',
      icon: PeopleOutline,
      collapse: collapse.representative,
      onClick: () => {
        handleCollapseChange('representative');
      },
      subItems: [
        {
          title: 'Listar Representantes',
          url: '/representative',
          icon: BorderAllOutlined,
          onClick: () =>
            handlePageToggle('/representative'),
          colors: getSideBarColor('/representative'),
        },
        {
          title: 'Cadastrar Representantes',
          url: '/representative/register',
          icon: ControlPoint,
          onClick: () =>
            handlePageToggle('/representative/register'),
          colors: getSideBarColor(
            '/representative/register'
          ),
        },
      ],
    },
    {
      title: 'Ofertas',
      url: 'bids-menu',
      icon: LocalOfferOutlined,
      onClick: () => {
        handleCollapseChange('bids');
      },
      collapse: collapse.bids,
      subItems: [
        {
          title: 'Listar Ofertas',
          url: '/bids',
          icon: BorderAllOutlined,
          onClick: () => handlePageToggle('/bids'),
          colors: getSideBarColor('/bids'),
        },
        {
          title: 'Cadastrar Ofertas',
          url: '/bids/register',
          icon: ControlPoint,
          onClick: () => handlePageToggle('/bids/register'),
          colors: getSideBarColor('/bids/register'),
        },
      ],
    },
    {
      title: 'Dados de Mercado',
      url: 'quotes-menu',
      icon: TrendingUp,
      collapse: collapse.marketData,
      onClick: () => {
        handleCollapseChange('marketData');
      },
      subItems: [
        {
          title: 'Listar dados',
          url: '/quotes',
          icon: BorderAllOutlined,
          onClick: () => handlePageToggle('/quotes'),
          colors: getSideBarColor('/quotes'),
        },
        {
          title: 'Cadastrar dados',
          url: '/quotes/register',
          icon: ControlPoint,
          onClick: () =>
            handlePageToggle('/quotes/register'),
          colors: getSideBarColor('/quotes/register'),
        },
      ],
    },
    {
      title: 'Barter',
      url: 'barter-menu',
      icon: SwapHoriz,
      collapse: collapse.barter,
      onClick: () => {
        handleCollapseChange('barter');
      },
      subItems: [
        {
          title: 'Listar Barters',
          url: '/barter',
          icon: BorderAllOutlined,
          onClick: () => handlePageToggle('/barter'),
          colors: getSideBarColor('/barter'),
        },
      ],
    },
    {
      title: 'Negociações',
      url: 'negotiations-menu',
      icon: Receipt,
      collapse: collapse.negotiations,
      onClick: () => {
        handleCollapseChange('negotiations');
      },
      subItems: [
        {
          title: 'Listar Negociações',
          url: '/negotiations',
          icon: BorderAllOutlined,
          onClick: () => handlePageToggle('/negotiations'),
          colors: getSideBarColor('/negotiations'),
        },
        {
          title: 'Cadastrar Negociação',
          url: '/negotiations/register',
          icon: ControlPoint,
          onClick: () =>
            handlePageToggle('/negotiations/register'),
          colors: getSideBarColor('/negotiations/register'),
        },
      ],
    },
    {
      title: 'Contrato',
      url: 'contract-menu',
      icon: NoteOutlined,
      collapse: collapse.contract,
      onClick: () => {
        handleCollapseChange('contract');
      },
      subItems: [
        {
          title: 'Criar Contrato',
          url: '/contract',
          icon: NoteAddOutlined,
          onClick: () => handlePageToggle('/contract'),
          colors: getSideBarColor('/contract'),
        },
        {
          title: 'Enviar Contrato',
          url: '/contract/upload',
          icon: FilePresentOutlined,
          onClick: () =>
            handlePageToggle('/contract/upload'),
          colors: getSideBarColor('/contract/upload'),
        },
      ],
    },
    {
      title: 'Contraproposta',
      url: 'counter-bid-menu',
      icon: HandshakeOutlined,
      collapse: collapse.counterBid,
      onClick: () => {
        handleCollapseChange('counterBid');
      },
      subItems: [
        {
          title: 'Listar Contrapropostas',
          url: '/counter-bid',
          icon: BorderAllOutlined,
          onClick: () => handlePageToggle('/counter-bid'),
          colors: getSideBarColor('/counter-bid'),
        },
        {
          title: 'Criar Contraproposta',
          url: '/counter-bid/register',
          icon: ControlPoint,
          onClick: () =>
            handlePageToggle('/counter-bid/register'),
          colors: getSideBarColor('/counter-bid/register'),
        },
      ],
    },
    {
      title: 'Financeiro',
      url: 'operations-menu',
      icon: AttachMoneyOutlined,
      collapse: collapse.operation,
      onClick: () => {
        handleCollapseChange('operation');
      },
      subItems: [
        {
          title: 'Listar Financeiro',
          url: '/operations',
          icon: BorderAllOutlined,
          onClick: () => handlePageToggle('/operations'),
          colors: getSideBarColor('/operations'),
        },
      ],
    },
  ];

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant='h6' noWrap component='div'>
          UAI Grãos
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {items.map((item) => (
          <>
            <ListItem
              key={item.url}
              disablePadding
              data-testid={item.url}
              onClick={item.onClick}
            >
              <ListItemButton>
                <ListItemIcon>
                  <item.icon htmlColor='#1976d2' />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    color: '#1976d2',
                  }}
                  primary={item.title}
                />
                {item.subItems ? (
                  item.collapse ? (
                    <ExpandLess htmlColor='#1976d2' />
                  ) : (
                    <ExpandMore htmlColor='#1976d2' />
                  )
                ) : null}
              </ListItemButton>
            </ListItem>
            {item?.subItems?.map((subItem) => (
              <Collapse
                in={item.collapse}
                timeout='auto'
                unmountOnExit
                key={subItem.url}
              >
                <List component='div' disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      background:
                        subItem.colors?.backgroundColor,
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                        '& .MuiListItemIcon-root, & .MuiListItemText-primary':
                          {
                            color: '#1976d2',
                          },
                      },
                    }}
                    onClick={subItem?.onClick}
                    data-cy={subItem?.url}
                    data-testid={subItem.url}
                  >
                    <ListItemIcon>
                      <subItem.icon
                        htmlColor={subItem.colors?.color}
                        sx={{
                          background:
                            subItem.colors?.backgroundColor,
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                            '& .MuiListItemIcon-root, & .MuiListItemText-primary':
                              {
                                color: '#1976d2',
                              },
                          },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        color: subItem.colors?.color,
                      }}
                      primary={subItem.title}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            ))}
          </>
        ))}
        {/* Botão de sair da conta */}
        <ListItem onClick={handleLogout}>
          <ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <LogoutOutlined color='error' />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  color: '#d32f2f',
                }}
                primary='Sair do Back-Office'
              />
            </ListItemButton>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    layout !== undefined
      ? () => layout().document.body
      : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        style={{ zIndex: 99 }}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuOutlined />
          </IconButton>
          <Typography variant='h6' noWrap component='div'>
            Back-Office
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label='mailbox folders'
      >
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        overflow='auto'
        component='main'
        height='100vh'
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <div style={{ padding: 20 }}>{props.children}</div>
      </Box>
    </Box>
  );
}
