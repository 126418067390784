import { useCallback, useState } from "react";
import { BarterProductService } from "uai-graos-sdk";
import { Barter } from "uai-graos-sdk/dist/main/@types/Barter";

export default function useBarter(){
    const [barterQuotes, setBarterQuotes] = useState<Barter.PagedBarterQuotation>()
    const [loading, setLoading] = useState<boolean>()

    const fetchBarterQuotes = useCallback(
        async (query : Barter.Query) => {
            setLoading(true)
            await BarterProductService.getBarterQuotation(query).then(setBarterQuotes)
            setLoading(false)
        },
        []
    )

    return {
        barterQuotes,
        fetchBarterQuotes,
        loading
    }


}